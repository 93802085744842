<template>
    <ItemView v-if="hasContentToShow" :item="item" :index="index" :hasDoneButton="false">
        <v-card-text>
            <!-- note to keep the 16:9 ratio between width/height -->
            <iframe width="400" height="225" :src="videoUrl" frameborder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <p class="text-body-1 mt-1" v-html="markdown(item.caption)"></p>
        </v-card-text>
    </ItemView>
</template>

<script>
import ItemView from './ItemView.vue';

export default {
    components: {
        ItemView
    },
    extends: ItemView,
    computed: {
        hasContentToShow() {
            return this.item.url !== "" || this.item.caption !== "";
        },
        videoUrl() {
            if (this.item && this.item.url) {
                const videoId = this.item.url.split('v=')[1];
                if (videoId) {
                    const ampersandPosition = videoId.indexOf('&');
                    if (ampersandPosition != -1) {
                        return 'https://www.youtube.com/embed/' + videoId.substring(0, ampersandPosition);
                    }
                    return 'https://www.youtube.com/embed/' + videoId;
                }
            }
            return '';
        },
    }

}
</script>