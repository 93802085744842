export function speakText(text, lang = 'en', voice = null) {
    // Check if the Speech Synthesis API is available in the browser
    if ('speechSynthesis' in window) {
        // Create a SpeechSynthesisUtterance object
        const utterance = new SpeechSynthesisUtterance(text);

        // If the voices are already loaded, set the voice immediately
        if (speechSynthesis.getVoices().length !== 0) {
            setVoice(utterance, lang, voice);
            speechSynthesis.cancel(); // stop current speech and clear the queue
            // Speak the utterance
            utterance.rate = 0.8; // Slow down the speech
            speechSynthesis.speak(utterance);
        } else {
            // Otherwise, set the voice when the voices are loaded
            speechSynthesis.onvoiceschanged = function () {
                setVoice(utterance, lang, voice);
                // Speak the utterance
                utterance.rate = 0.8; // Slow down the speech
                speechSynthesis.speak(utterance);
            };
        }
    }
}

function setVoice(utterance, lang, voice) {
    // Get the available voices
    const voices = speechSynthesis.getVoices();

    // If a voice is provided, find a voice that matches the specified voice
    let selectedVoice;
    if (voice) {
        selectedVoice = voices.find((v) => v.name === voice);
    }

    // If a matching voice is not found or a voice is not provided, find a voice that matches the specified language
    if (!selectedVoice) {
        selectedVoice = voices.find((v) => v.lang.startsWith(lang));
    }

    // If a matching voice is found, use that voice
    if (selectedVoice) {
        utterance.voice = selectedVoice;
    }
}


export function getVoiceNamesForLanguage(lang = 'en') {
    const voices = window.speechSynthesis.getVoices();
    return voices.filter(voice => voice.lang.startsWith(lang)).map(voice => voice.name);
}