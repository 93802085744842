// README
// - This is the main component for creating and editing activities.

<template>
    <div class="d-flex flex-column">
        <div class="menu-panel text-center d-flex align-center sticky mb-5"
            :class="{ 'mr-5': this.$isRTL(), 'ml-5': !this.$isRTL() }">
            <!-- Materials menu -->
            <v-menu open-on-hover :open-delay="50">
                <template v-slot:activator="{ props }">
                    <v-btn size="small" variant="flat" prepend-icon="mdi-plus" class="align-center" color="primary"
                        :class="{ 'ml-1': this.$isRTL(), 'mr-1': !this.$isRTL() }" v-bind="props">
                        {{ $t('activity_editor.material') }}
                    </v-btn>
                </template>
                <v-list density="compact">
                    <v-list-item @click="createItem('explanation')">
                        <v-list-item-title>{{ $t('activity_editor.explanation') }}</v-list-item-title>
                        <template v-slot:prepend>
                            <v-icon icon="mdi-message-text"></v-icon>
                        </template>
                        <template v-slot:append>
                            <span :class="getMenuItemClass()">{{ getShortcutText('e') }}</span>
                        </template>
                    </v-list-item>
                    <v-list-item @click="createItem('text')">
                        <v-list-item-title>{{ $t('activity_editor.text') }}</v-list-item-title>
                        <template v-slot:prepend>
                            <v-icon icon="mdi-book-open"></v-icon>
                        </template>
                        <template v-slot:append>
                            <span :class="getMenuItemClass()">{{ getShortcutText('t') }}</span>
                        </template>
                    </v-list-item>
                    <v-list-item @click="createItem('image')">
                        <v-list-item-title>{{ $t('activity_editor.image') }}</v-list-item-title>
                        <template v-slot:prepend>
                            <v-icon icon="mdi-image"></v-icon>
                        </template>
                        <template v-slot:append>
                            <span :class="getMenuItemClass()">{{ getShortcutText('i') }}</span>
                        </template>
                    </v-list-item>
                    <v-list-item @click="createItem('youtube')">
                        <v-list-item-title>{{ $t('activity_editor.youtube') }}</v-list-item-title>
                        <template v-slot:prepend>
                            <v-icon icon="mdi-youtube"></v-icon>
                        </template>
                        <template v-slot:append>
                            <span :class="getMenuItemClass()">{{ getShortcutText('y') }}</span>
                        </template>
                    </v-list-item>
                    <v-list-item @click="createItem('title')">
                        <v-list-item-title>{{ $t('activity_editor.title') }}</v-list-item-title>
                        <template v-slot:prepend>
                            <v-icon icon="mdi-format-title"></v-icon>
                        </template>
                        <template v-slot:append>
                            <span :class="getMenuItemClass()">{{ getShortcutText('l') }}</span>
                        </template>
                    </v-list-item>
                </v-list>
            </v-menu>
            <!-- Questions menu -->
            <v-menu open-on-hover :open-delay="50">
                <template v-slot:activator="{ props }">
                    <v-btn size="small" variant="flat" prepend-icon="mdi-plus" color="primary" v-bind="props"
                        :class="{ 'ml-1': this.$isRTL(), 'mr-1': !this.$isRTL() }">
                        {{ $t('activity_editor.question') }}
                    </v-btn>
                </template>

                <v-list density="compact">
                    <v-list-item @click="createItem('multiple_choice')">
                        <v-list-item-title>{{ $t('activity_editor.multiple_choice') }}</v-list-item-title>
                        <template v-slot:prepend>
                            <v-icon icon="mdi-gamepad-circle-up"></v-icon>
                        </template>
                        <template v-slot:append>
                            <span :class="getMenuItemClass()">{{ getShortcutText('m') }}</span>
                        </template>
                    </v-list-item>
                    <v-list-item @click="createItem('true_false')">
                        <v-list-item-title>{{ $t('activity_editor.true_false') }}</v-list-item-title>
                        <template v-slot:prepend>
                            <v-icon icon="mdi-order-bool-descending"></v-icon>
                        </template>
                        <template v-slot:append>
                            <span :class="getMenuItemClass()">{{ getShortcutText('f') }}</span>
                        </template>
                    </v-list-item>
                    <v-list-item @click="createItem('sort')">
                        <v-list-item-title>{{ $t('activity_editor.sort_items') }}</v-list-item-title>
                        <template v-slot:prepend>
                            <v-icon icon="mdi-sort"></v-icon>
                        </template>
                        <template v-slot:append>
                            <span :class="getMenuItemClass()">{{ getShortcutText('s') }}</span>
                        </template>
                    </v-list-item>
                    <v-list-item @click="createItem('mark_words')">
                        <v-list-item-title>{{ $t('activity_editor.mark_words') }}</v-list-item-title>
                        <template v-slot:prepend>
                            <v-icon icon="mdi-marker"></v-icon>
                        </template>
                        <template v-slot:append>
                            <span :class="getMenuItemClass()">{{ getShortcutText('w') }}</span>
                        </template>
                    </v-list-item>
                    <v-list-item @click="createItem('short_answer')">
                        <v-list-item-title>{{ $t('activity_editor.short_answer') }}</v-list-item-title>
                        <template v-slot:prepend>
                            <v-icon icon="mdi-text-short"></v-icon>
                        </template>
                        <template v-slot:append>
                            <span :class="getMenuItemClass()">{{ getShortcutText('a') }}</span>
                        </template>
                    </v-list-item>
                </v-list>
            </v-menu>
            <BulkGuestionGeneratorDialog />
            <!-- Save button -->
            <v-btn :disabled="saving" @click="save" :color="$store.getters.isDirty ? 'red' : 'green'" variant="flat"
                density="comfortable" :class="{ 'ml-2': this.$isRTL(), 'mr-2': !this.$isRTL() }">{{
                $t('activity_editor.save')
            }}</v-btn>
            <!-- Preview button -->
            <v-btn v-if="activity.docRef" icon flat @click="openActivityInNewTab">
                <v-icon>mdi-eye</v-icon>
                <v-tooltip activator="parent" location="bottom">{{ $t('activity_editor.activity_preview') }}</v-tooltip>
            </v-btn>
            <!-- Settings button -->
            <ActivitySettings v-model:dialog="dialog" @saved="save" />
            <span :class="{ 'mr-5': this.$isRTL(), 'ml-5': !this.$isRTL() }">{{
                $tc('activity_editor.number_of_questions',
                    questionItems.length) }}</span>
        </div>
        <!-- Forms for the added activity items -->
        <v-row dense>
            <v-col cols="12" v-for="(item, index) in items" :key="index"
                :ref="index === items.length - 1 ? 'lastItem' : null">
                <ExplanationForm v-if="item.type === 'material' && item.subtype === 'explanation'" :index="index" />
                <TextForm v-if="item.type === 'material' && item.subtype === 'text'" :index="index" />
                <ImageForm v-if="item.type === 'material' && item.subtype === 'image'" :index="index" />
                <YouTubeForm v-if="item.type === 'material' && item.subtype === 'youtube'" :index="index" />
                <TrueFalseQuestionForm v-if="item.type === 'question' && item.subtype === 'true_false'"
                    :index="index" />
                <SortItemsQuestionForm v-if="item.type === 'question' && item.subtype === 'sort'" :index="index" />
                <MarkWordsQuestionForm v-if="item.type === 'question' && item.subtype === 'mark_words'"
                    :index="index" />
                <ShortAnswerQuestionForm v-if="item.type === 'question' && item.subtype === 'short_answer'"
                    :index="index" />
                <MultipleChoiceQuestionForm v-if="item.type === 'question' && item.subtype === 'multiple_choice'"
                    :index="index" />
                <TitleForm v-if="item.type === 'accessory' && item.subtype === 'title'" :index="index" />
            </v-col>
        </v-row>
        <SnackBar />
    </div>
</template>



<script>
import * as activity from '@/components/activity/models/activity'
import ExplanationForm from '@/components/activity/forms/ExplanationForm.vue'
import TextForm from '@/components/activity/forms/TextForm.vue'
import TitleForm from '@/components/activity/forms/TitleForm.vue'
import ImageForm from '@/components/activity/forms/ImageForm.vue'
import YouTubeForm from '@/components/activity/forms/YouTubeForm.vue'
import TrueFalseQuestionForm from '@/components/activity/forms/TrueFalseQuestionForm.vue'
import SortItemsQuestionForm from '@/components/activity/forms/SortItemsQuestionForm.vue'
import MultipleChoiceQuestionForm from '@/components/activity/forms/MultipleChoiceQuestionForm.vue'
import MarkWordsQuestionForm from '@/components/activity/forms/MarkWordsQuestionForm.vue'
import ShortAnswerQuestionForm from '@/components/activity/forms/ShortAnswerQuestionForm.vue'
import ActivitySettings from '@/components/activity/forms/ActivitySettings.vue';
import SnackBar from '@/components/SnackBar.vue';
import BulkGuestionGeneratorDialog from '@/components/wizards/BulkGuestionGeneratorDialog.vue'
import { saveActivity } from '@/utils/firebaseUtils';
import { getAuth } from "firebase/auth";


export default {
    components: {
        ExplanationForm,
        YouTubeForm,
        TrueFalseQuestionForm,
        SortItemsQuestionForm,
        ActivitySettings,
        MultipleChoiceQuestionForm,
        ImageForm,
        TextForm,
        MarkWordsQuestionForm,
        ShortAnswerQuestionForm,
        TitleForm,
        SnackBar,
        BulkGuestionGeneratorDialog,
    },
    data() {
        return {
            fab: false,
            dialog: false,
            textareaValue: '',
            saving: false, // flag to prevent multiple saves
        }
    },
    computed: {
        items() {
            return this.$store.state.activity.items;
        },
        activity() {
            return this.$store.state.activity;
        },
        questionItems() {
            return this.activity.items.filter(item => Object.prototype.hasOwnProperty.call(item, 'grade'));
        },
    },
    methods: {
        createItem(subtype) {
            let item;
            switch (subtype) {
                case 'explanation':
                    item = new activity.Explanation();
                    break;
                case 'text':
                    item = new activity.Text();
                    break;
                case 'image':
                    item = new activity.Image();
                    break;
                case 'youtube':
                    item = new activity.YouTubeLink();
                    break;
                case 'true_false':
                    item = new activity.TrueFalseQuestion();
                    break;
                case 'sort':
                    item = new activity.SortItemsQuestion();
                    break;
                case 'multiple_choice':
                    item = new activity.MultipleChoiceQuestion();
                    break;
                case 'mark_words':
                    item = new activity.MarkWordsQuestion();
                    break;
                case 'short_answer':
                    item = new activity.ShortAnswerQuestion();
                    break;
                case 'title':
                    item = new activity.Title();
                    break;
                default:
                    throw new Error(`Unknown item subtype: ${subtype}`);
            }
            this.$store.commit('addItem', item);
            this.$emit('itemAdded');
        },
        async save() {
            this.saving = true;
            // Get the current user's ID and add it to the activity
            const userId = getAuth().currentUser.uid;
            this.activity.uid = userId;
            // add current locale to activity
            //this.activity.locale = this.$getLocale();

            // if activity name is empty, set it to a default value
            if (!this.activity.name) {
                this.$store.commit('SET_ACTIVITY_NAME', this.$t('my_activities.new_activity_name'));
            }

            const docRef = await saveActivity(this.activity);
            this.$store.commit('updateActivityDocRef', docRef);
            this.$store.commit('SET_ACTIVITY_SNAPSHOT');
            this.$showSnackbar(this.$t('activity_editor.activity_saved'));
            this.saving = false;
        },
        openActivityInNewTab() {
            const url = this.$getActivityURL(this.activity.docRef);
            // open the activity in a new tab
            window.open(url, '_blank');
        },
        handleKeyDown(event) {
            console.log(event);
            if (event.altKey && event.shiftKey) {
                switch (event.code) {
                    case 'KeyE':
                        this.createItem('explanation');
                        break;
                    case 'KeyT':
                        this.createItem('text');
                        break;
                    case 'KeyI':
                        this.createItem('image');
                        break;
                    case 'KeyY':
                        this.createItem('youtube');
                        break;
                    case 'KeyM':
                        this.createItem('multiple_choice');
                        break;
                    case 'KeyF':
                        this.createItem('true_false');
                        break;
                    case 'KeyS':
                        this.createItem('sort');
                        break;
                    case 'KeyW':
                        this.createItem('mark_words');
                        break;
                    case 'KeyA':
                        this.createItem('short_answer');
                        break;
                    case 'KeyL':
                        this.createItem('title');
                        break;
                    default:
                        break;
                }
            }
        },
        // This code will use navigator.userAgentData if it's available,
        // and fall back to navigator.platform if it's not. 
        getShortcutText(key) {
            const base = this.isMacOS() ? 'OPTION+SHIFT+' : 'ALT+SHIFT+';
            return base + key.toUpperCase();
        },
        isMacOS() {
            const userAgent = navigator.userAgent.toLowerCase();
            return userAgent.includes('macintosh') || userAgent.includes('mac os x');
        },
        getMenuItemClass() {
            return this.$isRTL() ? 'text-grey text-caption mr-7' : 'text-grey text-caption ml-7';
        },
    },
    created() {
        // listen to keydown events to handle item creation shortcuts
        window.addEventListener('keydown', this.handleKeyDown);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
    },

}
</script>

<style>
.sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
    margin: 0;
}

.menu-panel {
    align-self: flex-start;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    border-radius: 5px;
}
</style>