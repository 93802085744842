<template>
    <v-icon color="grey-darken-1" v-if="text2speech.enabled" size="x-small" @click="speak(text)"
        :class="{ 'mr-2': this.$isRTL(), 'ml-2': !this.$isRTL() }">mdi-volume-high</v-icon>
</template>

<script>
import { speakText } from '@/utils/text2speech';
import { mapGetters } from 'vuex';

export default {
    props: ['text'],
    computed: {
        ...mapGetters({
            text2speech: 'text2speech',
            activity: 'activity',
        }),
    },
    methods: {
        speak(text) {
            speakText(text, this.activity.locale, this.text2speech.selectedVoice);
        },
    },
}
</script>