<template>
    <v-row>
        <v-dialog v-model="dialog" persistent width="400">
            <template v-slot:activator="{ props }">
                <v-btn flat icon v-bind="props" color="transparent">
                    <v-icon>mdi-cog</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ $t('activity_settings.title') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-select v-model="localInteractionMode" :items="['training', 'quiz']"
                                    :label="$t('activity_settings.interaction_mode')" hide-details
                                    density="compact"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select v-model="localLocale" :items="locales" item-title="text" item-value="value"
                                    :label="$t('activity_settings.activity_language')" hide-details
                                    density="compact"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select v-model="localVoiceSupport" :items="['true', 'false']"
                                    :label="$t('solve_activity.text_to_speech')" hide-details
                                    density="compact"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-switch color="primary" v-model="localShowGrade"
                                    :label="$t('activity_settings.show_grade')" hide-details
                                    density="compact"></v-switch>
                            </v-col>
                            <!-- Share Link -->
                            <v-col cols="12">
                                <v-text-field v-model="shareLink" readonly append-icon="mdi-content-copy"
                                    @click:append="copyShareLink" :label="$t('common.link_to_activity')" hide-details
                                    density="compact"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions style="margin-top: -10px;">
                    <v-btn color="blue-darken-1" variant="text" @click="saveChanges">
                        {{ $t('activity_settings.save') }}
                    </v-btn>
                    <v-btn color="blue-darken-1" variant="text" @click="closeDialog">
                        {{ $t('activity_settings.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <SnackBar />
    </v-row>
</template>

<script>
import SnackBar from '@/components/SnackBar.vue';

export default {
    components: {
        SnackBar,
    },
    data: () => ({
        dialog: false,
        localInteractionMode: '',
        localShowGrade: '',
        localLocale: '',
        localVoiceSupport: false,
        shareLink: '',
        locales: [{ text: 'English', value: 'en' }, { text: 'עברית', value: 'he' }],
    }),
    computed: {
        activity() {
            return this.$store.state.activity;
        }
    },
    watch: {
        activity: {
            deep: true,
            immediate: true,
            handler(newValue) {
                this.localInteractionMode = newValue.interactionMode;
                this.localShowGrade = newValue.showGrade;
                this.localLocale = newValue.locale;
                this.localVoiceSupport = newValue.voiceSupport || false; // there may be no such field, a new addition
                if (newValue.docRef) {
                    this.shareLink = this.$getActivityURL(newValue.docRef);
                }
            },
        },
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },
        saveChanges() {
            this.$store.commit('SET_INTERACTION_MODE', this.localInteractionMode);
            this.$store.commit('SET_SHOW_GRADE', this.localShowGrade);
            this.$store.commit('SET_ACTIVITY_LOCALE', this.localLocale);
            this.$store.commit('SET_VOICE_SUPPORT', this.localVoiceSupport);
            this.closeDialog();
            this.$emit('saved'); // Notify parent component
        },
        copyShareLink() {
            navigator.clipboard.writeText(this.shareLink);
            this.$showSnackbar(this.$t('activity_settings.link_copied'));
        },
    },
};
</script>