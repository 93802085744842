<template>
  <v-row>
    <v-col cols="7" class="editor-container">
      <ActivityEditor @itemAdded="handleItemAdded" />
    </v-col>
    <!-- Note that ItemView depends on this class for edit/scrolling synch -->
    <v-col cols="5" class="preview-container">
      <ActivityPreview />
    </v-col>
  </v-row>
</template>

<script>
import ActivityEditor from '@/components/activity/ActivityEditor.vue'
import ActivityPreview from '@/components/activity/ActivityPreview.vue'
import { mapMutations } from 'vuex';

export default {
  components: {
    ActivityEditor,
    ActivityPreview
  },
  methods: {
    ...mapMutations([
      'setMuted' // map the setMuted mutation
    ]),
    // Scroll to the bottom of the editor when a new item is added
    handleItemAdded() {
      this.$nextTick(() => {
        const editorContainer = this.$el.querySelector('.editor-container');
        if (editorContainer) {
          editorContainer.scrollTo({
            top: editorContainer.scrollHeight,
            behavior: 'smooth'
          });
        }
      });
    },
  },
  created() {
    this.setMuted(true); // mute the sound
  },
}
</script>

<style scoped>
.preview-container {
  height: 93vh;
  /* Don't touch! looks good in deployment */
  overflow-y: auto;
}

.editor-container {
  height: 93vh;
  /* adjust this to fit your layout */
  overflow-y: auto;
}

/* We make the scrollbar invisible */
.preview-container::-webkit-scrollbar {
  width: 0px;
  /* Adjust as needed */
}

.preview-container::-webkit-scrollbar-thumb {
  background: transparent;
  /* Adjust color as needed */
}

.editor-container::-webkit-scrollbar {
  width: 0px;
  /* Adjust as needed */
}

.editor-container::-webkit-scrollbar-thumb {
  background: transparent;
  /* Adjust color as needed */
}
</style>