<template>
    <v-card class="ml-5 mr-5 mb-2" color="white">
        <v-card-title class="text-start">{{ $t(item.name) }}</v-card-title>
        <slot></slot>
        <v-card-text v-if="item.type === 'question'">
            <v-textarea :label="$t('activity_editor.feedback')" variant="underlined"
                v-model="item.feedback" rows="1" max-rows="8" hide-details auto-grow style="margin-top: -20px;" @focus="setActiveItem()"></v-textarea>

        </v-card-text>
        <v-card-actions>
            <v-btn color="purple" @click="deleteItem">{{ $t('activity_editor.delete') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'ItemForm',
    props: {
        index: {
            type: Number,
            required: true
        }
    },
    computed: {
        item() {
            return this.$store.state.activity.items[this.index];
        }
    },
    methods: {
        deleteItem() {
            this.$store.commit('deleteItem', this.index);
        },
        setActiveItem() {
            this.$store.commit('SET_ACTIVE_ITEM_INDEX', this.index);
        }
    },
}
</script>