<template>
  <div class="d-flex flex-column">
    <v-row>
      <v-col cols="6" class="scrollable" align="start">
        <!-- Buttons panel -->
        <div class="menu-panel sticky mr-5 ml-5 mb-3">
          <v-icon @click="selectedFolderId = null;">mdi-folder-upload-outline</v-icon>
          <v-btn  :disabled="!isPremium && numOfActivitiesLeft == 0" size="small" variant="flat" color="primary" @click="createNewActivity" :class="marginClass">{{
            $t('my_activities.create_activity')
          }}</v-btn>
          <v-btn size="small" variant="flat" color="primary" @click="folderDialog = true" :class="marginClass">{{
            $t('my_activities.create_folder')
          }}</v-btn>
        </div>
        <!-- Message panel -->
        <v-row v-if="!isPremium" class="message-panel sticky mr-5 ml-5 mb-3" align="center">
          {{ $tc('activity_editor.quota_left', numOfActivitiesLeft) }}
          <v-spacer></v-spacer>
          <v-btn size="small" variant="text" color="primary" @click="$router.push('/settings')" :class="marginClass">{{
            $t('activity_editor.upgrade')
          }}</v-btn>
        </v-row>
        <!-- Folders list -->
        <v-list v-if="foldersToShow.length > 0" lines="2" class="mr-5 ml-5" density="compact">
          <v-list-item v-for="(folder, index) in foldersToShow" :key="index" @dragover.prevent
            @drop="handleDrop($event, folder)" :title="`${folder.name} (${numOfActivitiesInFolder[folder.id] || 0})`"
            @click="selectFolder(folder)">
            <template v-slot:prepend>
              <v-icon color="primary">mdi-folder</v-icon> </template>
            <template v-slot:append>
              <v-btn icon="mdi-delete" size="small" variant="text" color="grey-darken-2"
                @click.stop="folderToDelete = folder.id; deleteFolderDialog = true"></v-btn>
            </template>
          </v-list-item>
        </v-list>

        <v-divider v-if="activitiesToShow.length > 0" class="mr-5 ml-5"></v-divider>

        <!-- Link to the folder, use $getFolderURL(folderId)  -->

        <!-- Activities list -->
        <v-list v-if="activitiesToShow.length > 0" lines="2" class="mr-5 ml-5" density="compact">
          <!-- Show & Copy Folder URL -->
          <v-list-item v-if="selectedFolderId">
            <template v-slot:prepend>
              <v-icon @click.stop="copyFolderLink">mdi-content-copy</v-icon>
            </template>
            <a :href="$getFolderURL(selectedFolderId)" target="_blank" class="text-body-2 text-grey-darken-1"
              style="text-decoration: none;">{{ $getFolderURL(selectedFolderId) }}</a>
          </v-list-item>
          <v-list-item v-for="(activity, index) in activitiesToShow" color="primary" :key="index" draggable="true"
            @dragstart="handleDragStart($event, activity)" @click="$store.dispatch('replaceActivity', activity)"
            :value="activity.docRef" :title="activity.name">
            <template v-slot:prepend>
              <v-icon>mdi-school</v-icon>
            </template>
            <template v-slot:append>
              <v-btn icon="mdi-pencil" size="small" variant="text" color="grey-darken-2" @click.stop="editActivity(activity)"></v-btn>
              <v-btn icon="mdi-share-variant" size="small" variant="text" color="grey-darken-2"
                @click.stop="activityToShare = activity; shareDialog = true"></v-btn>
              <v-btn icon="mdi-delete" size="small" variant="text" color="grey-darken-2"
                @click.stop="activityToDelete = activity; deleteActivityDialog = true"></v-btn>
            </template>
          </v-list-item>
        </v-list>
        <div class="mr-5 ml-5 mt-3" v-if="activitiesToShow.length === 0 && selectedFolderId">{{
            $t('my_activities.no_activities') }}</div>
      </v-col>
      <v-col v-if="currActivity.docRef" cols="6" class="scrollable">
        <ActivityPreview />
      </v-col>
    </v-row>
  </div>
  <!-- Delete activity dialog -->
  <v-dialog v-model="deleteActivityDialog" width="300px">
    <v-card>
      <v-card-title class="headline">{{ $t('my_activities.confirm_activity_delete') }}</v-card-title>
      <v-card-actions>
        <v-btn color="green darken-1" text @click="deleteActivity">{{ $t('my_activities.delete') }}</v-btn>
        <v-btn color="green darken-1" text @click="deleteActivityDialog = false">{{ $t('my_activities.cancel')
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Delete folder dialog -->
  <v-dialog v-model="deleteFolderDialog" width="300px">
    <v-card>
      <v-card-title class="headline">{{ $t('my_activities.confirm_folder_delete') }}</v-card-title>
      <v-card-text>{{ $t('my_activities.folder_delete_message') }}</v-card-text>
      <v-card-actions>
        <v-btn color="green darken-1" text @click="deleteFolder">{{ $t('my_activities.delete') }}</v-btn>
        <v-btn color="green darken-1" text @click="deleteFolderDialog = false">{{ $t('my_activities.cancel')
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Create folder dialog -->
  <v-dialog v-model="folderDialog" width="300px">
    <v-card>
      <v-card-title class="headline">{{ $t('my_activities.create_folder') }}</v-card-title>
      <v-card-text>
        <v-text-field v-model="newFolderName" :label="$t('my_activities.folder_name')" required></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green darken-1" text @click="createFolder">{{ $t('my_activities.confirm') }}</v-btn>
        <v-btn color="green darken-1" text @click="folderDialog = false">{{ $t('my_activities.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Share activity dialog -->
  <v-dialog v-model="shareDialog" width="500px">
    <v-card>
      <v-card-title class="headline">{{ $t('my_activities.share_activity') }}</v-card-title>
      <v-card-text>
        <v-text-field v-model="shareLink" readonly append-icon="mdi-content-copy" @click:append="copyShareLink"
          :label="$t('common.link_to_activity')" hide-details></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green darken-1" text @click="shareDialog = false">{{ $t('my_activities.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <SnackBar />
</template>

<script>
import ActivityPreview from '@/components/activity/ActivityPreview.vue'
import { deleteActivity as deleteActivityFromFirebase, addFolder, getFolders, saveActivity, deleteFolder as deleteFolderFromFirebase } from '@/utils/firebaseUtils';
import SnackBar from '@/components/SnackBar.vue';


export default {
  components: {
    ActivityPreview,
    SnackBar,
  },
  data() {
    return {
      deleteActivityDialog: false,
      deleteFolderDialog: false,
      folderDialog: false,
      shareDialog: false,
      activityToDelete: null,
      activityToShare: null,
      folderToDelete: null,
      newFolderName: '',
      folders: [],
      unsubscribe: null,
      selectedFolderId: null,
    };
  },
  computed: {
    activities() {
      // sort by name lexicographically
      return [...this.$store.state.activities].sort((a, b) => a.name.localeCompare(b.name));
    },
    numOfActivitiesLeft() {
      return 15 - this.activities.length;
    },
    isPremium() {
      return this.$store.state.userSettings.isPremium;
    },
    currActivity() {
      return this.$store.state.activity;
    },
    uid() {
      return this.$store.state.user.data.uid;
    },
    marginClass() {
      return this.$isRTL() ? 'mr-2' : 'ml-2';
    },
    activitiesToShow() {
      if (!this.selectedFolderId) {
        return this.activities.filter(activity => {
          if (!activity.folder) {
            return true;
          }

          const folderExists = this.folders.some(folder => folder.id === activity.folder);
          return !folderExists;
        });
      }

      return this.activities.filter(activity => activity.folder === this.selectedFolderId);
    },
    foldersToShow() {
      return this.folders.filter(folder => this.selectedFolderId === null || this.selectedFolderId === folder.id);
    },
    // return an object where each key is a folder id
    // and each value is the count of activities with that folder
    numOfActivitiesInFolder() {
      return this.activities.reduce((counts, activity) => {
        if (activity.folder in counts) {
          counts[activity.folder]++;
        } else {
          counts[activity.folder] = 1;
        }
        return counts;
      }, {});
    },
    shareLink() {
      if (this.activityToShare && this.activityToShare.docRef) {
        return this.$getActivityURL(this.activityToShare.docRef);
      }
      return '';
    },
  },
  methods: {
    createNewActivity() {
      this.$store.commit('RESET_ACTIVITY_STATE');
      this.$store.commit('SET_ACTIVITY_NAME', this.$t('my_activities.new_activity_name'));
      this.$store.commit('SET_ACTIVITY_FOLDER', this.selectedFolderId);
      this.$store.commit('SET_ACTIVITY_LOCALE', this.$getLocale()); // init with current locale
      this.$router.push('/create');
    },
    async createFolder() {
      this.folderDialog = false;
      if (this.newFolderName.trim() !== '') {
        await addFolder(this.uid, this.newFolderName);
        this.newFolderName = '';
      }
    },
    deleteFolder() {
      deleteFolderFromFirebase(this.folderToDelete);
      // we do not delete the activities. they still point to that folder
      // but we consider them as belong to the top level folder
      // therefore, we set selectedFolderId to null (to return to parent)
      this.selectedFolderId = null;
      this.deleteFolderDialog = false;
    },
    selectFolder(folder) {
      this.selectedFolderId = folder.id;
    },
    handleDragStart(event, activity) {
      // if we don't create the image, the whole list will be dragged... (only visually)
      // Create a new image
      const img = new Image();
      // Set the drag image to the new image
      event.dataTransfer.setDragImage(img, 0, 0);
      // Store the activity data in the dataTransfer object
      event.dataTransfer.setData('activity', JSON.stringify(activity));
    },
    handleDrop(event, folder) {
      // Retrieve the activity data from the dataTransfer object
      const activityData = JSON.parse(event.dataTransfer.getData('activity'));

      // Find the existing activity in the activities array
      const activity = this.activities.find(a => a.docRef === activityData.docRef);

      // Update the folder property of the activity
      if (activity) {
        activity.folder = folder.id;
        saveActivity(activity);
      }
    },
    editActivity(activity) {
      this.$store.dispatch('replaceActivity', activity);
      this.$router.push('/create');
    },
    deleteActivity() {
      deleteActivityFromFirebase(this.activityToDelete.docRef);
      this.deleteActivityDialog = false;
    },
    copyShareLink() {
      navigator.clipboard.writeText(this.shareLink);
      this.$showSnackbar(this.$t('activity_settings.link_copied'));
    },
    copyFolderLink() {
      navigator.clipboard.writeText(this.$getFolderURL(this.selectedFolderId));
      this.$showSnackbar(this.$t('activity_settings.link_copied'));
    },
  },
  created() {
    // we use a snapshot listener to keep the folders list updated
    // unlike the activities, where we maintain a local copy in the store
    this.unsubscribe = getFolders(this.uid, (querySnapshot) => {
      this.folders = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
}
</script>

<style scoped>
.scrollable {
  height: 90vh; /* Don't touch! looks good in deployment */
  overflow-y: auto;
}


/* We make the scrollbar invisible */
.scrollable::-webkit-scrollbar {
  width: 0px;
  /* Adjust as needed */
}

.scrollable::-webkit-scrollbar-thumb {
  background: transparent;
  /* Adjust color as needed */
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
  margin: 0;
}

.menu-panel {
  align-self: flex-start;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  border-radius: 5px;
}

.message-panel {
  align-self: flex-start;
  background-color: #E1BEE7;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); */
  padding: 5px 10px;
  /* border-radius: 5px; */
}
</style>