// training mode:
// - the student can select an answer and press 'done' many times
// - after each press, the student gets feedback for all answers
// quiz mode:
// - the student can select an answer and press 'done' only once
// - after the first press, the student gets feedback for all answers
<template>
    <ItemView :item="item" :index="index" @done="gradeQuestion">
        <v-card-text>
            <p class="text-body-1 mb-2">
                <span v-html="markdown(item.statement)"></span>
                <speak-text-icon :text="item.statement" />
            </p>
            <v-col cols="12">
                <v-chip @click="answerClicked(item, trueOption)" :color="chipColor(trueOption)"
                    :class="getChipClass(trueOption)" style="width: 300px;">
                    {{ trueOption }}
                </v-chip>
                <speak-text-icon :text="trueOption" />
            </v-col>
            <v-col cols="12">
                <v-chip @click="answerClicked(item, falseOption)" :color="chipColor(falseOption)"
                    :class="getChipClass(falseOption)" style="width: 300px;">
                    {{ falseOption }}
                </v-chip>
                <speak-text-icon :text="falseOption" />
            </v-col>
        </v-card-text>
    </ItemView>
</template>

<script>
import ItemView from './ItemView.vue';
import SpeakTextIcon from './SpeakTextIcon.vue';

export default {
    components: {
        ItemView,
        SpeakTextIcon
    },
    extends: ItemView,
    data() {
        return {
            doneClicked: false,
            trueOption: this.$t('activity_preview.true'),
            falseOption: this.$t('activity_preview.false'),
        }
    },
    watch: {
        // when the teacher changes the form, we "reset" the view
        'item.correct': {
            handler() {
                this.handleChangeInQuestionForm();
            },
            deep: true
        },
        'item.statement': {
            handler() {
                this.handleChangeInQuestionForm();
            },
            deep: true
        },
    },
    methods: {
        getChipClass(answer) {
            // if doneClicked and the answer equals item.studentAnswer,
            // we add a border to the chip
            if (this.doneClicked && answer === this.item.studentAnswer) {
                return 'custom-chip-border';
            } else {
                return 'custom-chip';
            }
        },
        answerClicked(item, answer) {
            if (!this.canAnswer) {
                return;
            }

            this.playTapSound();
            item.studentAnswer = answer;
            this.doneClicked = false; // allow the student to change his answer (if in training mode)
            this.item.grade = null; // reset grade
        },
        handleChangeInQuestionForm() {
            this.item.grade = null; // reset grade
            this.item.studentAnswer = ""; // reset student answer
            this.doneClicked = false;
            this.item.numAnswered = 0;
        },
        gradeQuestion() {
            if (this.item.studentAnswer === "") {
                return;
            }
            this.doneClicked = true;
            this.item.numAnswered++;

            // item.correct is boolean, item.studentAnswer is string
            var studentBoolAnswer = this.item.studentAnswer === this.trueOption;

            if (studentBoolAnswer === this.item.correct) {
                this.item.grade = 100;
            } else {
                this.item.grade = 0;
            }
            this.playDoneSound();
        },
        chipColor(answer) {
            // if the student has not answered yet, we only highlight the selected answer
            if (!this.doneClicked) {
                if (answer === this.item.studentAnswer) {
                    return 'primary';
                } else {
                    return 'grey-darken-5';
                }
            }

            // training/quiz mode, we provide feedback only for the selected answer
            if (answer === this.item.studentAnswer) {
                if (this.item.grade === 100) {
                    return 'green';
                } else {
                    return 'red';
                }
            } else {
                return 'grey-darken-5';
            }
        },
    }
}
</script>