
// guidelines:
// - When adding a new fields, update the toObject() method

// called for each item before saving to firebase (see firebaseUtils.js)
// for each new item: add a case to the switch statement
// why not in each class? since vuex converts some of the objects to plain objects
// and thus the class methods are not available...
export function clearStudentSelections(item) {
    switch (item.type) {
        case 'question':
            item.grade = null;
            item.numAnswered = 0;
            if (item.subtype === 'multiple_choice') {
                item.studentAnswer = '';
            } else if (item.subtype === 'true_false') {
                item.studentAnswer = '';
            } else if (item.subtype === 'sort') {
                // we skip, otherwise the items disappear from the teacher's view
                // upon save. It is ok since studentSortedItems is built from scratch
                // when fetched from firebase
                // item.studentSortedItems = [];
                break;
            } else if (item.subtype === 'mark_words') {
                item.studentSelectedWords = [];
            } else if (item.subtype === 'short_answer') {
                item.studentAnswer = '';
            }
            break;
        case 'material':
            // do nothing
            break;
        case 'accessory':
            // do nothing
            break;
        // Add more cases as needed
        default:
            return;
    }
}

export class BaseItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor(type = null, subtype = null) {
        this.type = type;
        this.subtype = subtype;
        this.name = '';
    }

    // firebase expects a plain object, not an instance of a class
    toObject() {
        return {
            type: this.type,
            subtype: this.subtype,
            name: this.name,
            // Add other properties here
        };
    }
}

export class QuestionItem extends BaseItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor(subtype) {
        super('question', subtype);
        this.grade = null; // will hold the student's grade
        this.numAnswered = 0; // how many times the student answered the question
        this.feedback = ''; // will hold feedback for the student (provided after answering)
    }
    toObject() {
        return {
            ...super.toObject(),
            grade: this.grade,
            numAnswered: this.numAnswered,
            feedback: this.feedback,
        };
    }
}

export class Title extends BaseItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor() {
        super('accessory', 'title');
        this.name = 'activity_editor.title';
        this.title = '';
        this.titleType = 'h1'; // h1, h2, h3, h4, h5, h6
    }
    toObject() {
        return {
            ...super.toObject(),
            title: this.title,
            titleType: this.titleType,
        };
    }
}

export class MaterialItem extends BaseItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor(subtype) {
        super('material', subtype);
        // Initialize properties common to all materials
        // ...
    }
    toObject() {
        return {
            ...super.toObject(),
            // Add other properties here
        };
    }
}

export class Explanation extends MaterialItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor() {
        super('explanation');
        this.name = 'activity_editor.explanation'; // key in en.js and he.js
        this.explanation = '';
    }
    toObject() {
        return {
            ...super.toObject(),
            explanation: this.explanation,
        };
    }
}

export class Text extends MaterialItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor() {
        super('text');
        this.name = 'activity_editor.text';
        this.title = '';
        this.text = '';
    }
    toObject() {
        return {
            ...super.toObject(),
            title: this.title,
            text: this.text,
        };
    }
}

export class Image extends MaterialItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor() {
        super('image');
        this.name = 'activity_editor.image';
        this.url = '';
        this.caption = '';
    }
    toObject() {
        return {
            ...super.toObject(),
            url: this.url,
            caption: this.caption,
        };
    }
}

export class YouTubeLink extends MaterialItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor() {
        super('youtube');
        this.name = 'activity_editor.youtube';
        this.url = '';
        this.caption = '';
    }
    toObject() {
        return {
            ...super.toObject(),
            url: this.url,
            caption: this.caption,
        };
    }
}

export class MultipleChoiceQuestion extends QuestionItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor() {
        super('multiple_choice');
        this.name = 'activity_editor.multiple_choice';
        this.question = '';
        this.correct = '';
        this.distractors = ['', '', '', ''];
        this.order = 'random';  // answers' order: 'random', 'ascend', 'descend'
        this.studentAnswer = ''; // will hold the student's answer
    }
    toObject() {
        return {
            ...super.toObject(),
            question: this.question,
            correct: this.correct,
            distractors: this.distractors,
            order: this.order,
            studentAnswer: this.studentAnswer,
        };
    }
    clearAnswer() {
        this.studentAnswer = '';
    }
}

export class TrueFalseQuestion extends QuestionItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor() {
        super('true_false');
        this.name = 'activity_editor.true_false';
        this.statement = '';
        this.correct = true;
        this.studentAnswer = ''; // will hold the student's answer
    }
    toObject() {
        return {
            ...super.toObject(),
            statement: this.statement,
            correct: this.correct,
            studentAnswer: this.studentAnswer,
        };
    }
    clearAnswer() {
        this.studentAnswer = '';
    }
}


export class ShortAnswerQuestion extends QuestionItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor() {
        super('short_answer');
        this.name = 'activity_editor.short_answer';
        this.question = '';
        this.possibleAnswers = Array(4).fill(''); // will hold the possible answers
        this.studentAnswer = ''; // will hold the student's answer
        this.setDefaultFeedback = false; // if true, the default feedback will be used (all student answers)
    }
    toObject() {
        return {
            ...super.toObject(),
            question: this.question,
            possibleAnswers: this.possibleAnswers,
            studentAnswer: this.studentAnswer,
            setDefaultFeedback: this.setDefaultFeedback,
        };
    }
    clearAnswer() {
        this.studentAnswer = '';
    }
}

export class SortItemsQuestion extends QuestionItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor() {
        super('sort');
        this.name = 'activity_editor.sort_items';
        this.guideline = '';
        this.sortedItems = Array(8).fill(''); // 8 empty strings, filled-in by teacher
        this.studentSortedItems = []; // holds student's sorted items
    }
    toObject() {
        return {
            ...super.toObject(),
            guideline: this.guideline,
            sortedItems: this.sortedItems,
            studentSortedItems: this.studentSortedItems,
        };
    }
    clearAnswer() {
        this.studentSortedItems = [];
    }
}
export class MarkWordsQuestion extends QuestionItem {
    // REMEMBER to update the toObject() method when adding new fields
    constructor() {
        super('mark_words');
        this.name = 'activity_editor.mark_words';
        this.question = '';
        this.text = '';
        this.selectedWordsRequired = [];
        this.selectedWordsOptional = [];
        this.studentSelectedWords = []; // holds student's sorted items
    }
    toObject() {
        return {
            ...super.toObject(),
            question: this.question,
            text: this.text,
            selectedWordsRequired: this.selectedWordsRequired,
            selectedWordsOptional: this.selectedWordsOptional,
            studentSelectedWords: this.studentSelectedWords,
        };
    }
    clearAnswer() {
        this.studentSelectedWords = [];
    }
}

// Add more classes as needed