// Localization notes:
// We use both vuetify and vue-i18n for localization.
// Vuetify is used for localization of vuetify components, such as the date picker.
// AND it sets the directionality of the app (rtl/ltr).
// Vue-i18n is used for localization of our own strings.
// We access the i18n object in our components using the $t() method.
// e.g., $t('navbar.create_activity') will return the string 'יצירת פעילות' in Hebrew.
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import { en, he } from 'vuetify/locale'

// i18n
import { createI18n } from 'vue-i18n'
// here we import our customized messages from the i18n folder
import enMessages from './i18n/en'
import heMessages from './i18n/he'

import './assets/MyStyles.css';

export const i18n = createI18n({
    locale: 'he', // set locale
    messages: { en: enMessages, he: heMessages }, // set locale messages
})

// firebase initialization is is src/firebase.js

const myCustomTheme = {
    dark: false,
    colors: {
        //background: '#FFFFFF',
        //surface: '#EEEEEE',
        primary: '#9C27B0',
        primarydarken4: '#4A148C',
        // secondary: '#03DAC6',
        // 'secondary-darken-1': '#018786',
        // error: '#B00020',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FB8C00',
    },
}

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'myCustomTheme',
        themes: {
            myCustomTheme,
        },
    },
    icons: {
        defaultSet: 'mdi',
    },
    locale: {
        locale: 'he',
        fallback: 'he',
        messages: { he, en },
        rtl: { he: true, en: false },
    },
})

const app = createApp(App)

// Global properties
app.config.globalProperties.$isRTL = function () {
    return this.$vuetify.locale.current === 'he'
}
app.config.globalProperties.$isRTLLang = function (lang) {
    return lang === 'he'
}
app.config.globalProperties.$getLocale = function () {
    return i18n.global.locale;
};
app.config.globalProperties.$setLocale = function (locale) {
    this.$vuetify.locale.current = locale;
    this.$i18n.locale = locale;
};
app.config.globalProperties.$showSnackbar = function (message) {
    this.$store.commit('showSnackbar', { message: message, show: true });
};
app.config.globalProperties.$getActivityURL = function (activityID) {
    if (process.env.VUE_APP_ENV === 'production') {
        return `https://letsstudy.io/activity?id=${activityID}`;
    } else {
        return `http://localhost:8080/activity?id=${activityID}`;
    }
};

app.config.globalProperties.$getFolderURL = function (folderID) {
    if (process.env.VUE_APP_ENV === 'production') {
        return `https://letsstudy.io/folder?id=${folderID}`;
    } else {
        return `http://localhost:8080/folder?id=${folderID}`;
    }
}

app.use(store).use(router).use(i18n).use(vuetify).mount('#app')

