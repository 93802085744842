<template>
  <ItemForm :index="index">
    <v-card-text>
      <v-textarea @focus="setActiveItem()" class="mb-3" variant="underlined" :label="$t('activity_editor.true_false_statement')" v-model="item.statement" rows="1" hide-details
        auto-grow autofocus></v-textarea>
      <v-switch @focus="setActiveItem()" v-model="item.correct" hide-details inset :label="item.correct ? $t('activity_preview.true') : $t('activity_preview.false')"
        style="margin-bottom: -15px;"></v-switch>
    </v-card-text>
  </ItemForm>
</template>

<script>
import ItemForm from './ItemForm';

export default {
  name: 'TrueFalseQuestionForm',
  components: {
    ItemForm
  },
  extends: ItemForm,
}
</script>