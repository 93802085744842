<template>
  <v-row>
    <!-- Black side panel - hidden for xs/sm -->
    <v-col cols="3" class="side-panel hidden-sm-and-down">
      <div class="created-with"><a href="https://letsstudy.io" target="_blank">{{ $t('solve_activity.created_with')
          }}</a></div>
    </v-col>
    <!-- Folder content (activities) -->
    <!-- pl-10 for xs/sm, pl-5 for > md -->
    <v-col cols="12" md="6" class="activity-preview pl-10 pr-10 pl-md-5 pr-md-5 pt-5"
      :style="{ backgroundImage: `url(${selectedImage})` }">
      <ActivityPreview />
      <div v-if="errorMessage" style="margin-top: 30px;">
        <p class="text-h4">Folder not found or access denied</p>
        <p class="text-h4">התיקייה לא נמצאה או אין הרשאת גישה</p>
      </div>
    </v-col>
    <!-- Black side panel - hidden for xs/sm -->
    <v-col cols="3" class="side-panel hidden-sm-and-down">
    </v-col>
  </v-row>
</template>

<script>
import { getFolder, getActivitiesByFolder } from '@/utils/firebaseUtils';
import * as activity from '@/components/activity/models/activity';
import ActivityPreview from '@/components/activity/ActivityPreview.vue';

export default {
  name: 'FolderView',
  components: {
    ActivityPreview,
  },
  data() {
    return {
      images: [
        require('@/assets/dots_1280.jpg'),
        require('@/assets/leaves_1280.png'),
        require('@/assets/seamless_1280.jpg'),
      ],
      errorMessage: false,
    };
  },
  computed: {
    activity() {
      return this.$store.state.activity;
    }
  },
  methods: {
    async fetchFolderActivities() {
      const folderId = this.$route.query.id;
      try {
        const folder = await getFolder(folderId);
        var items = [];
        var titleItem = new activity.Title();
        titleItem.title = folder.name;
        // will be pushed later, after sorting

        const activities = await getActivitiesByFolder(folderId);
        activities.forEach((a) => {
          var textItem = new activity.Text();
          var activityUrl = this.$getActivityURL(a.docRef);
          // set textItem.text with the activity name and link (markdown format)
          textItem.text = `[${a.name}](${activityUrl})`;
          items.push(textItem);
        });

        // sort items by text (activity name)
        items.sort((a, b) => a.text.localeCompare(b.text));

        // push the title item to the beginning of the array
        items.unshift(titleItem);

        // Dispatch the updateItems action
        this.$store.dispatch('updateItems', items);

        document.title = "Let's Study | " + folder.name;
      } catch (error) {
        // missing or insufficient permissions / folder not found
        console.error(error);
        this.errorMessage = true;
      }
    },
  },
  async created() {
    this.selectedImage = this.images[Math.floor(Math.random() * this.images.length)];
    await this.fetchFolderActivities();
  },
};
</script>
<style scoped>
.side-panel {
  background-color: black;
  box-sizing: border-box;
  min-height: 100vh;
}

.activity-preview {
  background-repeat: repeat;
  background-size: 25%;
  min-height: 100vh;
  box-sizing: border-box;
}

.user-panel {
  position: fixed;
  margin: 20px;
}

.quiz-perfect-image {
  width: 100%;
  /* make the image take the full width of its container */
  height: auto;
  /* keep the original aspect ratio */
}

.created-with {
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 15px;
  padding-bottom: 10px;
  font-size: 0.8em;
  color: white;
}

.created-with a {
  color: white;
  text-decoration: none;
}
</style>