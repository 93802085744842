<template>
    <div>
        <v-table :class="{ 'text-right ml-10 mr-10': $isRTL(), 'text-left ml-10 mr-10': !$isRTL() }"
            density="comfortable">
            <thead>
                <tr>
                    <th>{{ $t('answers.activity_name') }}</th>
                    <th>{{ $t('answers.answered_at') }}</th>
                    <th>{{ $t('answers.grade') }}</th>
                    <th>{{ $t('answers.student_name') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="answer in answers" :key="answer.createdAt">
                    <td>{{ answer.activityName }}</td>
                    <td>{{ new Date(answer.createdAt.seconds * 1000).toLocaleDateString() }}</td>
                    <td>{{ answer.grade }}</td>
                    <td>{{ answer.username }}</td>
                </tr>
            </tbody>
        </v-table>
    </div>
</template>

<script>
import { getAnswers } from '@/utils/firebaseUtils';

export default {
    data() {
        return {
            answers: [],
        };
    },
    computed: {
        teacherId() {
            return this.$store.state.user.data.uid;
        },
    },
    async created() {
        this.answers = await getAnswers(this.teacherId);
    },
}
</script>