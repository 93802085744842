<template>
  <ItemForm :index="index">
    <v-card-text>
      <v-textarea :label="$t('activity_editor.text')" variant="underlined" v-model="item.explanation" rows="1"
        hide-details auto-grow @focus="setActiveItem()" autofocus></v-textarea>
    </v-card-text>
  </ItemForm>
</template>

<script>
import ItemForm from './ItemForm';

export default {
  name: 'ExplanationForm',
  components: {
    ItemForm
  },
  extends: ItemForm,
}
</script>