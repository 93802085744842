<template>
    <div class="fixed-panel">
        <v-row dense :class="`${$isRTL() ? 'mr-5' : 'ml-5'} mt-3`">
            <!-- Volume on/off -->
            <v-col cols="12" align="start">
                <v-btn icon size="small" @click="toggleMute">
                    <v-icon>{{ isMuted ? 'mdi-volume-mute' : 'mdi-volume-high' }}</v-icon>
                </v-btn>
            </v-col>
            <!-- Text2Speech enable/disable -->
            <v-col v-if="hasVoiceSupport" cols="12" align="start">
                <v-switch color="white" v-model="$store.state.text2speech.enabled" label="Text2Speech" hide-details>
                    <template v-slot:label>
                        <span class="text-white">{{ $t('solve_activity.text_to_speech') }}</span>
                    </template>
                </v-switch>
            </v-col>
            <!-- Text2Speech voice selection -->
            <v-col v-if="hasVoiceSupport" cols="12" align="start">
                <v-select v-if="text2speech.enabled" density="compact" bg-color="white" :items="voiceNames"
                    :label="$t('solve_activity.select_voice')" v-model="text2speech.selectedVoice"
                    style="max-width: 200px;"></v-select>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { getVoiceNamesForLanguage } from '@/utils/text2speech.js';

export default {
    data() {
        return {
            voiceNames: [],
        }
    },
    computed: {
        isMuted() {
            return this.$store.getters.muted;
        },
        text2speech() {
            return this.$store.state.text2speech;
        },
        activity() {
            return this.$store.state.activity;
        },
        hasVoiceSupport() {
            return this.activity.voiceSupport || false;
        },
    },
    watch: {
        'text2speech.enabled'(newVal) {
            if (newVal) {
                this.voiceNames = getVoiceNamesForLanguage(this.activity.locale);
            }
        },
    },
    methods: {
        toggleMute() {
            this.$store.commit('setMuted', !this.$store.getters.muted);
        },
    },
    created() {
        // for init purposes, we do not use the voices yet.
        getVoiceNamesForLanguage();
    },
}
</script>

<style scoped>
.fixed-panel {
    position: fixed;
}
</style>