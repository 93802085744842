// components/wizards/QuestionTypes.js
import * as activity from '@/components/activity/models/activity'
import { i18n } from '@/main';

const delimiter = '|';

export class MultipleChoiceAutoDistractorsGenerator {
    validateInput(input) {
        // Check if the input is empty
        if (!input) {
            return 'Please enter some values';
        }

        input = input.trim();

        // Split the input into lines
        var lines = input.split('\n');

        // if the first line is a csv header, remove it
        lines = removeHeaderLine(lines);

        // Check if each line is in the form (question,correct,feedback - optional)
        for (let i = 0; i < lines.length; i++) {
            // Gemini LMS adds leading or trailing pipes, so we remove them
            const line = removeLeadingAndTrailingPipe(lines[i]);
            const parts = line.split(delimiter);
            if (parts.length !== 2 && parts.length !== 3) {
                return `Line ${i + 1} must be in the form (question | correct | explanation - optional)`;
            }
            if (parts[1].trim() === '') {
                return `The second item (correct) cannot be empty in line ${i + 1}`;
            }
        }

        // check that there are at least 4 unique distractors
        const distractors = lines.map(line => line.split(delimiter)[1].trim());
        const uniqueDistractors = [...new Set(distractors)];
        if (uniqueDistractors.length < 3) {
            return 'There must be at least 3 unique distractors';
        }

        return true;
    }

    generateQuestions(input) {
        // Split the input into lines
        var lines = input.trim().split('\n');

        // if the first line is a csv header, remove it
        lines = removeHeaderLine(lines);

        // Create a MultipleChoiceQuestion object for each line
        const questions = lines.map(line => {
            const [question, correct, feedback] = removeLeadingAndTrailingPipe(line).split(delimiter).map(item => removeOptionalSurroundingQuotes(item.trim()));

            const multipleChoiceQuestion = new activity.MultipleChoiceQuestion();
            multipleChoiceQuestion.question = question;
            multipleChoiceQuestion.correct = correct;
            multipleChoiceQuestion.feedback = feedback || '';

            return multipleChoiceQuestion;
        });

        // Set the distractors for each question
        questions.forEach((question, index) => {
            // Create an array of the other questions
            const otherQuestions = [...questions];
            otherQuestions.splice(index, 1);

            // we may have duplicate distractors, so we need to remove them
            const uniqueDistractors = [...new Set(otherQuestions.map(q => q.correct))];

            // also (possibly) remove a distractor equals to the correct answer
            const i = uniqueDistractors.indexOf(question.correct);
            if (i !== -1) {
                uniqueDistractors.splice(i, 1);
            }

            // Select random questions from the other questions
            const numOfDistractors = Math.min(3, uniqueDistractors.length); // at most 3 distractors
            const distractors = [];
            for (let i = 0; i < numOfDistractors; i++) {
                const randomIndex = Math.floor(Math.random() * uniqueDistractors.length);
                distractors.push(uniqueDistractors[randomIndex]);
                uniqueDistractors.splice(randomIndex, 1);
            }
            // add another empty distractor (for the user to probably fill in)
            distractors.push('');
            // Set the distractors
            question.distractors = distractors;
        });

        return questions;
    }

    placeholder() {
        return i18n.global.t("wizard.multiple_choice_example_nodist");
    }

    label() {
        return i18n.global.t("wizard.multiple_choice_label_nodist");
    }
}
export class MultipleChoiceGenerator {
    validateInput(input) {
        // Check if the input is empty
        if (!input) {
            return 'Please enter some values';
        }

        input = input.trim();

        // Split the input into lines
        var lines = input.split('\n');

        // Gemini LMS adds leading or trailing pipes, so we remove them
        lines = lines.map(line => removeLeadingAndTrailingPipe(line));

        // if the first line is a csv header, remove it
        lines = removeHeaderLine(lines);

        // Check if each line is in the form (question|answer1|answer2|answer3|answer4|correct_index|explanation - optional)
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const parts = line.split(delimiter);
            if (parts.length !== 6 && parts.length !== 7) {
                return `Line ${i + 1} must be in the form (question | answer1 | answer2 | answer3 | answer4 | correct_index | explanation - optional)`;
            }
            // first six items must not be empty
            for (let j = 0; j < 6; j++) {
                if (parts[j].trim() === '') {
                    return `The item ${j + 1} cannot be empty in line ${i + 1}`;
                }
            }

            // check that the correct index is a number between 1 and 4
            const correctIndex = parseInt(parts[5].trim());
            if (isNaN(correctIndex) || correctIndex < 1 || correctIndex > 4) {
                return `The correct index must be a number between 1 and 4 in line ${i + 1}`;
            }

            // check that the four answers are unique
            const answers = parts.slice(1, 5);
            const uniqueAnswers = [...new Set(answers)];
            if (uniqueAnswers.length < 4) {
                return `The four answers must be unique in line ${i + 1}`;
            }
        }

        return true;
    }


    generateQuestions(input) {
        // Split the input into lines
        var lines = input.trim().split('\n');

        // if the first line is a csv header, remove it
        lines = removeHeaderLine(lines);

        // Create a MultipleChoiceQuestion object for each line
        const questions = lines.map(line => {
            const [question, answer1, answer2, answer3, answer4, correctIndex, explanation] = removeLeadingAndTrailingPipe(line).split(delimiter).map(item => removeOptionalSurroundingQuotes(item.trim()));

            const multipleChoiceQuestion = new activity.MultipleChoiceQuestion();
            multipleChoiceQuestion.question = question;
            var answers = [answer1, answer2, answer3, answer4];
            var correct = answers[parseInt(correctIndex) - 1];
            // remove the correct answer from the answers array
            answers.splice(parseInt(correctIndex) - 1, 1);
            multipleChoiceQuestion.distractors = [...answers, ''];
            multipleChoiceQuestion.correct = correct;
            multipleChoiceQuestion.feedback = explanation || '';

            return multipleChoiceQuestion;
        });

        return questions;
    }

    placeholder() {
        return i18n.global.t("wizard.multiple_choice_example");
    }

    label() {
        return i18n.global.t("wizard.multiple_choice_label");
    }
}

export class TrueFalseGenerator {
    validateInput(input) {
        // Check if the input is empty
        if (!input) {
            return 'Please enter some values';
        }

        input = input.trim();

        // Split the input into lines
        var lines = input.split('\n');

        // if the first line is a csv header, remove it
        lines = removeHeaderLine(lines);

        // Check if each line is in the form (statement | 0/1 | explanation - optional)
        for (let i = 0; i < lines.length; i++) {
            // Gemini LMS adds leading or trailing pipes, so we remove them
            const line = removeLeadingAndTrailingPipe(lines[i]);
            const parts = line.split(delimiter);
            if ((parts.length !== 2 && parts.length !== 3) || (parts[1].trim() !== '0' && parts[1].trim() !== '1')) {
                return `Line ${i + 1} must be in the form (statement | 0/1 | explanation - optional)`;
            }
        }

        // check that there is at least 1 line
        if (lines.length < 1) {
            return 'There must be at least 1 line.';
        }

        return true;
    }

    generateQuestions(input) {
        // Split the input into lines
        var lines = input.trim().split('\n');
        lines = removeHeaderLine(lines);

        // Create a TrueFalseQuestion object for each line
        const questions = lines.map(line => {
            const [statement, correct, feedback] = removeLeadingAndTrailingPipe(line).split(delimiter).map(item => removeOptionalSurroundingQuotes(item.trim()));

            const trueFalseQuestion = new activity.TrueFalseQuestion();
            trueFalseQuestion.statement = statement;
            trueFalseQuestion.correct = correct === '1' ? true : false;
            trueFalseQuestion.feedback = feedback || '';

            return trueFalseQuestion;
        });

        return questions;
    }

    placeholder() {
        return i18n.global.t("wizard.true_false_example");
    }

    label() {
        return i18n.global.t("wizard.true_false_label");
    }
}

export class SortItemsGenerator {
    validateInput(input) {
        // Check if the input is empty
        if (!input) {
            return 'Please enter some values';
        }

        input = input.trim();

        // Split the input into lines
        const lines = input.split('\n');

        // Each lines is a list of items separated by commas
        for (let i = 0; i < lines.length; i++) {
            const items = removeLeadingAndTrailingPipe(lines[i]).split(delimiter);
            // remove empty items
            const filteredItems = items.filter(item => item.trim() !== '');
            if (filteredItems.length < 2) {
                return `Line ${i + 1} must contain at least 2 items`;
            }
            if (filteredItems.length > 8) {
                return `Line ${i + 1} must contain at most 8 items`;
            }
        }

        // check that there is at least 1 line
        if (lines.length < 1) {
            return 'There must be at least 1 line.';
        }

        return true;
    }

    generateQuestions(input) {
        // Split the input into lines
        const lines = input.trim().split('\n');

        // Create a SortItemsQuestion object for each line
        const questions = lines.map(line => {
            const items = removeLeadingAndTrailingPipe(line).split(delimiter).map(item => item.trim());

            const sortItemsQuestion = new activity.SortItemsQuestion();
            // set items and expand the array to 8 items (if less than 8)
            sortItemsQuestion.sortedItems = items.concat(Array(8 - items.length).fill(''));
            return sortItemsQuestion;
        });

        return questions;
    }

    placeholder() {
        return i18n.global.t("wizard.sort_items_example");
    }

    label() {
        return i18n.global.t("wizard.sort_items_label");
    }
}


export class ShortAnswerGenerator {
    validateInput(input) {
        // Check if the input is empty
        if (!input) {
            return 'Please enter some values';
        }

        input = input.trim();

        // Split the input into lines
        var lines = input.split('\n');

        // if the first line is a csv header, remove it
        lines = removeHeaderLine(lines);

        // Each lines is a list of items separated by a pipe | character
        // (question | answer1 | answer2 | ...)
        for (let i = 0; i < lines.length; i++) {
            const items = removeLeadingAndTrailingPipe(lines[i]).split(delimiter);
            if (items.length < 2) {
                return `Line ${i + 1} must contain at least a question and an answer`;
            }
            // check that there is at most 4 answers
            if (items.length > 5) {
                return `Line ${i + 1} must contain at most 4 answers`;
            }
        }

        // check that there is at least 1 line
        if (lines.length < 1) {
            return 'There must be at least 1 line.';
        }

        return true;
    }

    generateQuestions(input) {
        // Split the input into lines
        var lines = input.trim().split('\n');

        // if the first line is a csv header, remove it
        lines = removeHeaderLine(lines);

        // Create a ShortAnswerQuestion object for each line
        const questions = lines.map(line => {
            line = removeLeadingAndTrailingPipe(line);
            const question = line.split(delimiter)[0].trim();
            const answers = line.split(delimiter).slice(1).map(item => item.trim());

            const shortAnswerQuestion = new activity.ShortAnswerQuestion();
            // set question and possibleAnswers (expand the array to 4 answers if less than 4)
            shortAnswerQuestion.question = question;
            shortAnswerQuestion.possibleAnswers = answers.concat(Array(4 - answers.length).fill(''));
            // set the first answer as the feedback
            shortAnswerQuestion.feedback = answers[0];
            return shortAnswerQuestion;
        });

        return questions;
    }

    placeholder() {
        return i18n.global.t("wizard.short_answer_example");
    }

    label() {
        return i18n.global.t("wizard.short_answer_label");
    }
}

export class MarkWordsGenerator {
    validateInput(input) {
        // Check if the input is empty
        if (!input) {
            return 'Please enter some values';
        }

        input = input.trim();

        // Split the input into lines
        const lines = input.split('\n');

        // check that there is at least 1 line
        if (lines.length < 1) {
            return 'There must be at least 1 line.';
        }

        // check that each lines has two items separated by a comma
        for (let i = 0; i < lines.length; i++) {
            var line = lines[i];
            line = removeLeadingAndTrailingPipe(line);
            const items = line.split(delimiter);
            if (items.length !== 2) {
                return `Line ${i + 1} must contain exactly two items: question, text`;
            }
        }

        // the second item (the answer) should have at least one word that is preceded by a *
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const items = line.split(delimiter);
            const answer = items[1].trim();
            const words = answer.split(/\s+/);
            let hasStar = false;
            for (const word of words) {
                if (word.startsWith('*')) {
                    hasStar = true;
                    break;
                }
            }
            if (!hasStar) {
                return `Line ${i + 1} must contain at least one word preceded by a *`;
            }
        }

        return true;
    }

    generateQuestions(input) {
        // Split the input into lines
        const lines = input.trim().split('\n');

        // Create a MarkWordsQuestion object for each line
        const questions = lines.map(line => {
            line = removeLeadingAndTrailingPipe(line);
            const question = line.split(delimiter)[0].trim();
            // text is the second item in the line, without the '*' characters
            const text = line.split(delimiter)[1].trim().replace(/\*/g, '');

            const markWordsQuestion = new activity.MarkWordsQuestion();
            markWordsQuestion.question = question;
            markWordsQuestion.text = text;
            // set the indices of the words that should be marked or optional
            const words = line.split(delimiter)[1].trim();
            const selectedWordsRequired = [];
            const selectedWordsOptional = [];
            words.split(/\s+/).forEach((word, index) => {
                if (word.startsWith('**')) {
                    selectedWordsOptional.push(index);
                } else if (word.startsWith('*')) {
                    selectedWordsRequired.push(index);
                }
            });
            markWordsQuestion.selectedWordsRequired = selectedWordsRequired;
            markWordsQuestion.selectedWordsOptional = selectedWordsOptional;
            return markWordsQuestion;
        });

        return questions;
    }

    placeholder() {
        return i18n.global.t("wizard.mark_words_example");
    }

    label() {
        return i18n.global.t("wizard.mark_words_label");
    }
}

function removeOptionalSurroundingQuotes(str) {
    // remove from the string escaped quotes if they are present
    str = str.replace(/\\"/g, '"');
    // remove surrounding quotes if they are present
    if (str.startsWith('"') && str.endsWith('"')) {
        return str.slice(1, -1);
    }
    return str;
}

function removeLeadingAndTrailingPipe(str) {
    str = str.trim();
    return str.replace(/^\|/, '').replace(/\|$/, '').trim();
}

function removeHeaderLine(lines) {
    // if the first line is a csv header, remove it
    // An header starts with "statement |" or "statement|" or "question| " or "question |"
    if (lines[0].match(/^\s*statement\s*\|/) || lines[0].match(/^\s*question\s*\|/)) {
        lines.shift();
    }
    return lines;
}