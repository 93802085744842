<template>
  <ItemForm :index="index">
    <v-card-text>
      <v-textarea class="mb-3" variant="underlined" :label="$t('activity_editor.guideline')" v-model="item.guideline" rows="1" hide-details
        auto-grow autofocus @focus="setActiveItem()"></v-textarea>
      <v-row dense>
        <v-col cols="6" v-for="(itemToSort, i) in item.sortedItems" :key="i">
          <v-text-field density="compact" v-model="item.sortedItems[i]" :label="$t('activity_editor.item') + ' ' + (i + 1)" hide-details variant="outlined" @focus="setActiveItem()"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </ItemForm>
</template>

<script>
import ItemForm from './ItemForm';

export default {
  name: 'SortItemsQuestionForm',
  components: {
    ItemForm
  },
  extends: ItemForm,
}
</script>