<template>
    <v-row dense>
        <v-col cols="12">
            <v-text-field v-if="$route.name !== 'SolveActivity' && $route.name !== 'FolderView'"
                :label="$t('activity_preview.activity_name')" v-model="$store.state.activity.name" density="comfortable"
                :class="{ 'ml-10': this.$isRTL(), 'mr-10': !this.$isRTL() }" :readonly="$route.path === '/manage'" />
        </v-col>
        <v-col cols="12" align="start" v-for="(item, index) in items" :key="index">
            <ExplanationView v-if="item.subtype === 'explanation'" :item="item" :index="index" />
            <TextView v-if="item.subtype === 'text'" :item="item" :index="index" />
            <ImageView v-if="item.subtype === 'image'" :item="item" :index="index" />
            <YouTubeView v-if="item.subtype === 'youtube'" :item="item" :index="index" />
            <TrueFalseQuestionView v-if="item.subtype === 'true_false'" :item="item" :index="index" />
            <SortItemsQuestionView v-if="item.subtype === 'sort'" :item="item" :index="index" />
            <MultipleChoiceQuestionView v-if="item.subtype === 'multiple_choice'" :item="item" :index="index" />
            <MarkWordsQuestionView v-if="item.subtype === 'mark_words'" :item="item" :index="index" />
            <ShortAnswerQuestionView v-if="item.subtype === 'short_answer'" :item="item" :index="index" />
            <TitleView v-if="item.subtype === 'title'" :item="item" :index="index" />
        </v-col>
    </v-row>
</template>

<script>
import ExplanationView from '@/components/activity/views/ExplanationView';
import TextView from '@/components/activity/views/TextView';
import TitleView from '@/components/activity/views/TitleView';
import ImageView from '@/components/activity/views/ImageView';
import YouTubeView from '@/components/activity/views/YouTubeView';
import TrueFalseQuestionView from '@/components/activity/views/TrueFalseQuestionView';
import SortItemsQuestionView from '@/components/activity/views/SortItemsQuestionView';
import MultipleChoiceQuestionView from '@/components/activity/views/MultipleChoiceQuestionView.vue';
import MarkWordsQuestionView from '@/components/activity/views/MarkWordsQuestionView.vue';
import ShortAnswerQuestionView from '@/components/activity/views/ShortAnswerQuestionView.vue';

export default {
    components: {
        ExplanationView,
        YouTubeView,
        TrueFalseQuestionView,
        SortItemsQuestionView,
        MultipleChoiceQuestionView,
        ShortAnswerQuestionView,
        ImageView,
        TextView,
        MarkWordsQuestionView,
        TitleView,
    },
    computed: {
        items() {
            return this.$store.state.activity.items;
        },
    }
}
</script>