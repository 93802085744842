<!--
- Each activity item should extend this component ('extends: ItemView').
- Each activity item should include this component in its template ('<ItemView :item="item">').
    - prop 'bgColor' sets the background color of the bubble. Default to while (for questions).
- Item of type 'material' should set the prop 'hasDoneButton' to false, and set the prop 'bgColor' to '#E1BEE7'.

Responsiblity taken by this component:
- Render the bubble.
- Render the 'Done' button.
- Disable the 'Done' button based on the interaction mode.
- Render the grade (if 'activity.showGrade' is true).
- Render the grade and icon, based on the grade value.

Expected by the activity item:
- emit 'done' event when the 'Done' button is clicked.
- increment 'item.numAnswered' when the 'Done' button is clicked.
- set 'item.grade' to the grade value when the 'Done' button is clicked.
- set 'item.grade' to null when no grade is available.
-->
<template>
    <div :class="[$isRTLLang(activity.locale) ? 'chat-bubble-rtl' : 'chat-bubble', 'text-start']"
        :style="{ 'background-color': bgColor, '--bubble-color': bgColor }">
        <div :ref="'item-' + index"></div>
        <v-locale-provider :rtl="$isRTLLang(activity.locale)">
            <v-card flat :color="bgColor">
                <slot></slot>
                <!-- Feedback (for questions) -->
                <v-card-text v-if="shouldShowFeedback">
                    <v-locale-provider :rtl="isRtlLang(item.feedback)">
                        <div class="text-grey-darken-1" style="margin-top: -15px;" v-html="markdown(item.feedback)">
                        </div>
                    </v-locale-provider>
                </v-card-text>
                <v-card-actions v-if="hasDoneButton" style="margin-top: -15px;">
                    <v-btn v-if="hasDoneButton" text :disabled="!canAnswer" color="primary"
                        @click="handleDoneClicked">{{
        $t('activity_preview.check') }}</v-btn>
                    <v-spacer></v-spacer>
                    <span v-if="hasDoneButton && activity.showGrade && item.grade !== null">{{ formattedGrade }}</span>
                    <v-icon v-if="hasDoneButton && item.grade !== null" class="ml-1 mr-1" :color="gradeColor">{{
        gradeIcon
                        }}</v-icon>
                </v-card-actions>
            </v-card>
        </v-locale-provider>
    </div>
</template>

<script>
import soundUtils from '@/utils/soundUtils.js';
import { speakText } from '@/utils/text2speech';
import { mapGetters } from 'vuex';
import markdownMixin from './markdownMixin';

export default {
    mixins: [markdownMixin],
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        bgColor: {
            type: String,
            default: '#FFFFFF'
        },
        hasDoneButton: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        ...mapGetters({
            isMuted: 'muted', // map 'muted' getter to 'isMuted' computed property
            'text2speech': 'text2speech',
            'activity': 'activity',
            'activeItemIndex': 'activeItemIndex'
        }),
        gradeIcon() {
            if (this.item.grade === 100) return 'mdi-checkbox-marked-circle-auto-outline';
            else if (this.item.grade >= 90) return 'mdi-checkbox-marked-circle-outline';
            else if (this.item.grade >= 80) return 'mdi-checkbox-marked-circle-minus-outline';
            else if (this.item.grade >= 70) return 'mdi-checkbox-marked-circle-minus-outline';
            else return 'mdi-close';
        },
        gradeColor() {
            if (this.item.grade >= 80) return 'green';
            else if (this.item.grade >= 70) return 'orange';
            else return 'red';
        },
        formattedGrade() {
            return Math.round(this.item.grade);
        },
        canAnswer() {
            return this.isTrainingMode || this.item.numAnswered === 0;
        },
        isTrainingMode() {
            return this.activity.interactionMode === 'training';
        },
        shouldShowFeedback() {
            return this.item.type === 'question' && this.item.feedback && this.item.grade !== null;
        },
        questionItems() {
            return this.activity.items.filter(item => Object.prototype.hasOwnProperty.call(item, 'grade'));
        },
        fullyAnswered() {
            return this.questionItems.every(item => item.grade !== null);
        },
        averageGrade() {
            const gradedItems = this.questionItems.filter(item => item.grade !== null);
            const totalGrade = gradedItems.reduce((total, item) => total + item.grade, 0);
            return (gradedItems.length > 0) ? Math.round(totalGrade / gradedItems.length) : 0;
        }
    },
    watch: {
        // scroll to an item view when it is focused.
        // this code was simpler, but after the global change
        // we made in index.html (overflow-y: hidden !important;)
        // it caused the navbar to be "pushed up". Now we scroll
        // only in the boundary of the preview container.
        // note: we depend on a class 'preview-container' in the parent CreateActivity.vue
        // it is not a good practice, but it is the best solution for now.
        activeItemIndex(newVal) {
            if (newVal !== null && newVal === this.index) {
                this.$nextTick(() => {
                    const element = this.$refs['item-' + this.index];
                    if (element) {
                        const rect = element.getBoundingClientRect();
                        const editorContainer = document.querySelector('.preview-container');
                        if (editorContainer) {
                            const containerRect = editorContainer.getBoundingClientRect();
                            const relativeTop = rect.top - containerRect.top;
                            editorContainer.scrollTo({
                                top: editorContainer.scrollTop + relativeTop,
                                behavior: 'smooth'
                            });
                        }
                    }
                    // why set to null? in case that the user scrolled
                    // the preview and then gave again focus to the item
                    // by that we will scroll to the item again
                    this.$store.commit('SET_ACTIVE_ITEM_INDEX', null);
                });
            }
        },
    },
    methods: {
        speakText(text) {
            speakText(text, this.activity.locale, this.text2speech.selectedVoice);
        },
        handleDoneClicked() {
            this.$emit('done');
        },
        playDoneSound() {
            if (this.activity.interactionMode === 'quiz' && this.fullyAnswered && this.averageGrade === 100) {
                soundUtils.playFinishActivityPerfectSound(this.isMuted);
                return;
            }
            if (this.fullyAnswered) {
                soundUtils.playFinishActivitySound(this.isMuted);
                return;
            }
            if (this.item.grade >= 80) {
                soundUtils.playSuccessSound(this.isMuted);
            } else {
                soundUtils.playFailureSound(this.isMuted);
            }
        },
        playTapSound() {
            soundUtils.playTapSound(this.isMuted);
        },
        // return true if the text contains any Hebrew characters
        // note: we use this function to determine if the text is RTL
        isRtlLang(text) {
            let hasHebrewChar = /[\u0590-\u05FF]/.test(text);
            let HasArabicChar = /[\u0600-\u06FF]/.test(text);

            return hasHebrewChar || HasArabicChar;

        },
    }
}
</script>

<style>
.chat-bubble {
    position: relative;
    border-radius: 0 10px 10px 10px;
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
    min-width: 200px;
    max-width: 450px;
}

.chat-bubble:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0px;
    border-width: 0 25px 25px 0;
    border-style: solid;
    border-color: transparent var(--bubble-color) transparent transparent;
}

.chat-bubble-rtl {
    position: relative;
    border-radius: 10px 0px 10px 10px;
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
    /* wrap to content */
    min-width: 200px;
    max-width: 450px;
}

.chat-bubble-rtl:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    border-width: 25px 25px 0 0;
    border-style: solid;
    border-color: var(--bubble-color) transparent transparent transparent;
}
</style>