import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../firebase' // import auth service
import TeacherAuth from '../views/TeacherAuth.vue'
import MyActivities from '../views/MyActivities.vue'
import CreateActivity from '../views/CreateActivity.vue'
import SolveActivity from '../views/SolveActivity.vue'
import FolderView from '../views/FolderView.vue'
import StudentAnswers from '../views/StudentAnswers.vue'
import TeacherSettings from '../views/TeacherSettings.vue'
import CertificatePage from '../views/CertificatePage.vue'
import HelpCenter from '../views/HelpCenter.vue'

const routes = [
  {
    path: '/',
    redirect: '/manage'
  },
  {
    path: '/manage',
    name: 'MyActivities',
    component: MyActivities,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'TeacherSettings',
    component: TeacherSettings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/create',
    name: 'CreateActivity',
    component: CreateActivity,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/answers',
    name: 'StudentAnswers',
    component: StudentAnswers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/activity',
    name: 'SolveActivity',
    component: SolveActivity
  },
  {
    path: '/folder',
    name: 'FolderView',
    component: FolderView
  },
  {
    path: '/certificate',
    name: 'CertificatePage',
    component: CertificatePage
  },
  {
    path: '/help',
    name: 'HelpCenter',
    component: HelpCenter
  },
  {
    path: '/auth',
    name: 'TeacherAuth',
    component: TeacherAuth
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/activity' || to.path === '/folder' || to.path === '/help') {
    document.documentElement.classList.add('disable-overflow');
    document.body.classList.add('student-route');
  } else {
    document.documentElement.classList.remove('disable-overflow');
    document.body.classList.remove('student-route');
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const user = auth.currentUser;

  if (requiresAuth && !user) {
    next('/auth');
  } else if (to.path === '/auth' && user) {
    next('/manage');
  } else {
    next();
  }
});

// due to the asynchronous nature of Firebase's auth.onAuthStateChanged(),
// when a user logs in, Firebase might not have finished updating the user's
// sign-in state, so user might be null at the time the navigation guard checks it.
auth.onAuthStateChanged(user => {
  if (user && router.currentRoute.value.path === '/auth') {
    router.push('/manage');
  }
});

export default router