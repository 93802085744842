<template>
    <div>
        <h1 v-if="item.titleType == 'h1'">{{ item.title }}</h1>
        <h2 v-if="item.titleType == 'h2'">{{ item.title }}</h2>
        <h3 v-if="item.titleType == 'h3'">{{ item.title }}</h3>
    </div>
</template>

<script>
import ItemView from './ItemView.vue';

export default {
    extends: ItemView,
    data() {
        return {
            isExpanded: false,
            maxCharsShown: 700,
        };
    },
    computed: {
        hasContentToShow() {
            // true if title or text is not empty
            return this.item.title !== "" || this.item.text !== "";
        },
        displayedText() {
            if (this.isExpanded || this.item.text.length <= this.maxCharsShown) {
                return this.item.text;
            }
            // cutoff ensures that we don't cut off in the middle of a word
            let cutOff = this.item.text.slice(0, this.maxCharsShown).lastIndexOf(' ');
            if (cutOff === -1) {
                cutOff = this.maxCharsShown;
            }
            return this.item.text.slice(0, cutOff) + '...';
        },
    },
}
</script>

<style scoped>
.no-padding {
    padding: 0 !important;
}
</style>