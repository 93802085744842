<template>
    <ItemView v-if="hasContentToShow" :item="item" :index="index" :hasDoneButton="false">
        <v-card-text>
            <v-img :src="item.url" width="300" aspect-ratio="16/9" cover @error="onImageError"></v-img>
            <p v-if="imageError" class="error-message">Error loading image</p>
            <p class="text-body-1 mt-1" v-html="markdown(item.caption)"></p>
        </v-card-text>
    </ItemView>
</template>

<script>
import ItemView from './ItemView.vue';

export default {
    components: {
        ItemView
    },
    extends: ItemView,
    data() {
        return {
            imageError: false
        }
    },
    // watch item.url, set imageError to false
    watch: {
        'item.url': function () {
            this.imageError = false;
        }
    },
    computed: {
        hasContentToShow() {
            return this.item.url !== "" || this.item.caption !== "";
        },
    },
    methods: {
        onImageError() {
            this.imageError = true;
        }
    }
}
</script>