// README
// - We define here the v-select to switch between languages
// - And also the greeting message to the user
// We load elements only after userSettings.docRef is not null,
// Otherwise screen "jumps" from default lang to that in the settings.
// Tried to define it in the parent div but it doesn't work
<template>
  <v-app style="background-color: #EEEEEE;">
    <div v-if="(!isStudentRoute && userSettings.docRef) || $route.path === '/auth' || $route.path === '/help'">
      <NavBar />
      <v-select density="compact" variant="outlined" class="language-select ml-2 mt-2" :items="languages"
        item-title="text" item-value="value" v-model="locale"></v-select>
      <div v-if="$store.state.user.loggedIn" class="greeting">{{ $t('navbar.greeting') }}, {{
      userSettings.name }}</div>
    </div>
    <!-- Progress bar to show until userSettings are set -->
    <v-row v-if="!userSettings.docRef && !isStudentRoute && $route.path !== '/auth' && $route.path !== '/help'" justify="center" align="center">
      <v-col cols="12">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
    <router-view v-if="userSettings.docRef || isStudentRoute || $route.path === '/auth' || $route.path === '/help'" />
    <v-main>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  components: {
    NavBar
  },
  data() {
    return {
      locale: null,
      languages: [
        { text: 'English', value: 'en' },
        { text: 'עברית', value: 'he' }
      ]
    }
  },
  computed: {
    userSettings() {
      return this.$store.state.userSettings;
    },
    appLocale() {
      return this.$store.state.appLocale;
    },
    isStudentRoute() {
      return this.$route.path === '/activity' || this.$route.path === '/folder' || this.$route.path === '/certificate';
    }
  },
  watch: {
    locale(newLocale) {
      this.$vuetify.locale.current = newLocale;
      this.$i18n.locale = newLocale;
    },
    // user may change the language in the settings page
    // we reflect the change in the navbar
    userSettings: {
      handler() {
        this.locale = this.userSettings.language;
      },
      immediate: true
    }
  },
  mounted() {
    this.locale = this.$vuetify.locale.current;
  },
}
</script>

<style>
html,
body {
  /* We need this in addition to the color set in v-app */
  /* Otherwise we have a white stripe at the bottom */
  background-color: #EEEEEE;
}

/* We make the stripe black if in student's route */
body.student-route {
  background-color: black;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.language-select {
  width: 120px;
  position: absolute;
  top: 0;
  left: 0;
}

.greeting {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 10px;
  padding-right: 20px;
}
</style>
