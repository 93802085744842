// src/i18n/he.js
// https://stackoverflow.com/questions/64139078/escaping-a-pipe-in-a-vue-i18n-translation
export default {
    common: {
        server_connection_error: 'שגיאת חיבור לשרת.',
        link_to_activity: 'קישור לפעילות',
    },
    settings: {
        name: 'שם המורה (מוצג לתלמידים)',
        language: 'שפת ברירת המחדל (של הממשק והפעילויות)',
        saved_message: 'ההגדרות נשמרו בהצלחה.',
        save_settings: 'שמירת הגדרות',
        premium_account: 'זהו חשבון <b>פרימיום</b>, המאפשר לכם ליצור פעילויות ללא הגבלה.',
        regular_account: 'זהו חשבון <b>רגיל</b>, המאפשר לכם ליצור עד <b>15 פעילויות בחינם</b>.',
        upgrade_to_premium: 'מעוניינים לשדרג <b>לפרימיום</b>? צרו קשר עם:',
        downgrade_to_regular: 'מעוניינים לשנות <b>לרגיל</b>? צרו קשר עם:'
    },
    navbar: {
        create_activity: 'עורך פעילות',
        my_activities: 'הפעילויות שלי',
        student_answers: 'מענה',
        login: 'התחברות',
        logout: 'יציאה',
        greeting: 'שלום',
        settings: 'הגדרות',
        help: 'עזרה',
    },
    help: {
        title_faq: 'שאלות נפוצות',
        title_tutorials: 'מדריכים',
        contact_us: 'צרו קשר בנוגע לשיפורים, דיווח על באגים או חשבון פרימיום:',
        tutorial_initial_login: 'התחברות ראשונית והגדרות',
        tutorial_create_first_activity: 'איך ליצור פעילות ראשונה?',
        tutorial_activity_components: 'הכרת רכיבי הפעילות',
        q_difference_google_forms: 'במה זה שונה מ-Google Forms?',
        a_difference_google_forms: 'Google Forms הנו מחולל כללי ליצירת טפסים, בעוד ש-LetsStudy.io הנה פלטפורמה ייעודית המותאמת ליצירת פעילויות למידה אינטראקטיביות. היא מציעה מגוון סוגי שאלות, כולל בחירה מרובה, נכון/לא נכון, מיון פריטים ועוד. כמו כן, היא מציעה מצב אימון, בו תלמידים יכולים לענות על השאלות עד שהם מצליחים, ומצב מבחן, בו ניתן לענות על כל שאלה פעם אחת בלבד.',
        q_is_it_free: 'האם צריך לשלם כדי להשתמש במערכת?',
        a_is_it_free: 'ניתן ליצור עד 15 פעילויות בחינם. אם נדרשת כמות גדולה יותר, ניתן לשדרג לחשבון פרימיום על ידי יצירת קשר איתנו (פרטים בתחתית העמוד).',
        q_who_developed: 'מי מפתח את LetsStudy.io?',
        a_who_developed: 'המערכת מפותחת על ידי ד"ר אורן משלי, חוקר ומרצה בטכניון ובאוניברסיטה הפתוחה. פרויקט זה הנו יוזמה אישית שלו, שאינה קשורה למוסדות בהם הוא מלמד.'
    },
    auth_screen: {
        welcome: "Let's Study",
        please_login: 'יש להתחבר באמצעות חשבון גוגל.',
        google_button: 'כניסה',
    },
    activity_editor: {
        material: 'חומר',
        question: 'שאלה',
        explanation: 'הסבר',
        text: 'טקסט',
        image: 'תמונה',
        youtube: 'YouTube',
        youtube_url: 'כתובת YouTube',
        multiple_choice: 'רב-ברירה',
        multiple_choice_nodist: 'רב-ברירה (מסיחים אוטומטיים)',
        true_false: 'נכון/לא נכון',
        sort_items: 'מיון פריטים',
        mark_words: 'סימון מילים',
        short_answer: 'תשובה קצרה',
        delete: 'מחיקה',
        title: 'כותרת',
        url: 'כתובת URL',
        caption: 'תיאור',
        correct: 'תשובה נכונה',
        distractor: 'מסיח',
        mark_required: 'סימון מילים הכרחיות',
        mark_optional: 'סימון מילים אפשריות',
        main_title: 'ראשית',
        secondary_title: 'משנית',
        save: 'שמירה',
        activity_saved: 'הפעילות נשמרה בהצלחה.',
        feedback: 'הסבר',
        true_false_statement: 'טענה',
        guideline: 'הנחיה',
        item: 'פריט',
        answer: 'תשובה',
        card_view: 'תצוגת כרטיסיות',
        json_view: 'תצוגת JSON',
        number_of_questions: 'אין שאלות | שאלה אחת | {count} שאלות',
        activity_preview: 'תצוגה מקדימה בלשונית חדשה',
        short_answer_default_explanation: 'השתמש בתשובות הנכונות כהסבר',
        quota_left: "הגעת למספר המירבי של פעילויות. | ניתן ליצור פעילות אחת נוספת. | ניתן ליצור עוד {count} פעילויות נוספות.",
        upgrade: "שדרג",
    },
    wizard: {
        title: 'מחולל שאלות מרובות',
        multiple_choice_label_nodist: "מבנה שורה: שאלה {'|'} תשובה נכונה {'|'} הסבר (אופציונלי)",
        multiple_choice_label: "מבנה שורה: שאלה {'|'} תשובה1 {'|'} ... {'|'} תשובה4 {'|'} אינדקס תשובה נכונה {'|'} הסבר (אופציונלי)",
        multiple_choice_example_nodist: "מהי עיר הבירה של ישראל? {'|'} ירושלים",
        multiple_choice_example: "מהי עיר הבירה של ישראל? {'|'} ירושלים {'|'} תל אביב {'|'} חיפה {'|'} באר שבע {'|'} 1 {'|'} ירושלים היא בירת ישראל.",
        true_false_label: "מבנה שורה: טענה {'|'} 1/0 {'|'} הסבר (אופציונלי)",
        true_false_example: "ירושלים היא בירת ישראל. {'|'} 1",
        sort_items_label: "הזינו פריטים ממוינים: פריט 1 {'|'} פריט 2 {'|'} ...",
        sort_items_example: "ראשון {'|'} שני {'|'} שלישי {'|'} רביעי {'|'} חמישי",
        short_answer_label: "מבנה שורה: שאלה {'|'} תשובה1 {'|'} תשובה2 {'|'} ...",
        short_answer_example: "איזה יום מגיע אחרי יום שני? שלישי {'|'} יום שלישי",
        mark_words_label: "מבנה שורה: שאלה {'|'} טקסט. הוסיפו * לפני מילות חובה, ** עבור מילות רשות.",
        mark_words_example: "סמנו את הפעלים. {'|'} דני *רץ מהר לבית הספר.",
        cancel: 'ביטול',
        generate: 'יצירת שאלות',
        close: 'סגירה',
    },
    activity_preview: {
        check: 'בדיקה',
        activity_name: 'שם הפעילות',
        true: 'נכון',
        false: 'לא נכון',
        answer: 'תשובה',
        words: "אין מילים | מילה אחת | {count} מילים",
    },
    activity_settings: {
        title: 'הגדרות פעילות',
        interaction_mode: 'מוד אינטראקציה',
        show_grade: 'הצג ציון לכל שאלה',
        save: 'שמירה',
        close: 'סגירה',
        link_copied: 'הקישור הועתק ללוח.',
        activity_language: 'שפת הפעילות',
    },
    my_activities: {
        create_activity: 'פעילות חדשה',
        create_folder: 'תיקייה חדשה',
        share_activity: 'שיתוף פעילות',
        folder_name: 'שם התיקייה',
        new_activity_name: 'פעילות ללא שם',
        confirm_activity_delete: 'למחוק את הפעילות?',
        confirm_folder_delete: 'למחוק את התיקייה?',
        delete: 'מחיקה',
        cancel: 'ביטול',
        confirm: 'אישור',
        close: 'סגירה',
        no_activities: 'אין פעילויות בתיקייה זו.',
        folder_delete_message: 'הפעילויות בתיקייה אינן נמחקות ומועברות לתיקייה הראשית.',

    },
    solve_activity: {
        text_to_speech: 'קריאה קולית',
        select_voice: 'בחירת קול',
        restart_activity: 'התחל מחדש',
        training_help: 'ניתן לענות על כל שאלה כמה פעמים, עד שמצליחים.',
        quiz_help: 'ניתן לענות על כל שאלה פעם אחת בלבד.',
        switch_to_quiz: 'עבור למבחן',
        switch_to_training: 'עבור לאימון',
        training_mode: 'מצב אימון',
        quiz_mode: 'מצב מבחן',
        your_grade_is: 'הציון שלך הוא {grade}',
        my_name: 'השם שלי',
        send: 'שליחה',
        enter_your_name: 'עליך להזין את השם שלך.',
        sent_to_teacher: 'נשלח בהצלחה למורה.',
        created_with: 'נוצר באמצעות LetsStudy.io',
    },
    answers: {
        activity_name: 'שם הפעילות',
        answered_at: 'נענה בתאריך',
        grade: 'ציון',
        student_name: 'שם התלמיד',
    },
    certificate: {
        show_certificate: 'הצגת תעודה',
    }
    // other translations...
}