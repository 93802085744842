<template>
    <div>
        <h1 class="mb-8">{{ $t('auth_screen.welcome') }}</h1>
        <h3 class="mb-2">{{ $t('auth_screen.please_login') }}</h3>
        <v-btn variant="flat" size="large" color="primary" @click="loginWithGoogle">{{ $t('auth_screen.google_button') }}</v-btn>
    </div>
</template>

<script>
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase' // import auth service

export default {
    methods: {
        async loginWithGoogle() {
            try {
                const provider = new GoogleAuthProvider();
                await signInWithPopup(auth, provider);
                this.$router.push('/manage');
            } catch (error) {
                console.error(error);
            }
        }
    }
}
</script>