// soundService.js

// sounds are loaded here, once.
const sounds = {
    tap: new Audio(require('@/assets/tap.mp3')),
    failure: new Audio(require('@/assets/failure.mp3')),
    success: new Audio(require('@/assets/success.mp3')),
    finishActivity: new Audio(require('@/assets/finish_activity.mp3')),
    finishActivityPerfect: new Audio(require('@/assets/finish_activity_perfect.mp3')),
    // add more sounds as needed
};

export default {
    playTapSound(muted) {
        if (!muted) {
            sounds.tap.currentTime = 0;
            sounds.tap.volume = 0.1; // set volume to 10% for this sound
            sounds.tap.play();
        }
    },
    playFailureSound(muted) {
        if (!muted) {
            sounds.failure.currentTime = 0;
            sounds.failure.volume = 0.5;
            sounds.failure.play();
        }
    },
    playSuccessSound(muted) {
        if (!muted) {
            sounds.success.currentTime = 0;
            sounds.success.volume = 0.5;
            sounds.success.play();
        }
    },
    playFinishActivitySound(muted) {
        if (!muted) {
            sounds.finishActivity.currentTime = 0;
            sounds.finishActivity.volume = 0.5;
            sounds.finishActivity.play();
        }
    },
    playFinishActivityPerfectSound(muted) {
        if (!muted) {
            sounds.finishActivityPerfect.currentTime = 0;
            sounds.finishActivityPerfect.volume = 0.5;
            sounds.finishActivityPerfect.play();
        }
    },
    // add more methods as needed
};