// training mode:
// - the student can select an answer and press 'done' many times
// - after each press, the student gets feedback only for the selected answer
// (we don't want to reveal the correct answer)
// quiz mode:
// - the student can select an answer and press 'done' only once
// - after the first press, the student gets feedback for all answers
<template>
    <ItemView v-if="hasContentToShow" :item="item" :index="index" @done="gradeQuestion">
        <v-card-text>
            <v-locale-provider :rtl="isRtlLang(item.question)">
                <p class="text-body-1 mb-2">
                    <span v-html="markdown(item.question)"></span>
                    <speak-text-icon v-if="item.question" :text="item.question" />
                </p>
            </v-locale-provider>

            <v-col cols="12" v-for="(answer, index) in allAnswers" :key="index">
                <v-locale-provider :rtl="isRtlLang(answer)">
                    <v-chip @click="answerClicked(item, answer)" :color="chipColor(answer)"
                        :class="getChipClass(answer)" style="width: 300px;">
                        {{ answer }}
                    </v-chip>
                    <speak-text-icon :text="answer" />
                </v-locale-provider>
            </v-col>
        </v-card-text>
    </ItemView>
</template>

<script>
import ItemView from './ItemView.vue';
import SpeakTextIcon from './SpeakTextIcon.vue';

export default {
    components: {
        ItemView,
        SpeakTextIcon
    },
    extends: ItemView,
    data() {
        return {
            allAnswers: [],
            doneClicked: false,
        }
    },
    created() {
        this.allAnswers = this.shuffleItems([...this.getAnswers()]);
    },
    computed: {
        hasContentToShow() {
            return this.item.question !== "" || this.item.correct !== "";
        },
    },
    watch: {
        // when the teacher changes the form, we "reset" the view
        'item.correct': {
            handler() {
                this.handleChangeInQuestionForm();
            },
            deep: true
        },
        'item.distractors': {
            handler() {
                this.handleChangeInQuestionForm();
            },
            deep: true
        }
    },
    methods: {
        getChipClass(answer) {
            // if doneClicked and the answer equals item.studentAnswer, we add a border to the chip
            if (this.doneClicked && answer === this.item.studentAnswer) {
                return 'custom-chip-border';
            } else {
                return 'custom-chip';
            }
        },
        answerClicked(item, answer) {
            if (!this.canAnswer) {
                return;
            }

            this.playTapSound();
            item.studentAnswer = answer;
            this.doneClicked = false; // allow the student to change his answer (if in training mode)
            this.item.grade = null; // reset grade
        },
        handleChangeInQuestionForm() {
            this.allAnswers = this.shuffleItems([...this.getAnswers()]);
            this.item.studentAnswer = ""; // reset student answer
            this.item.grade = null; // reset grade
            this.doneClicked = false;
            this.item.numAnswered = 0;
        },
        shuffleItems(array) {
            // Fisher-Yates shuffle algorithm
            let currentIndex = array.length, temporaryValue, randomIndex;
            while (0 !== currentIndex) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }
            return array;
        },
        getAnswers() {
            let answers = [...this.item.distractors];
            answers.push(this.item.correct);
            // remove empty strings
            answers = answers.filter(item => item !== '');
            return answers;
        },
        gradeQuestion() {
            if (this.item.studentAnswer === "") {
                return;
            }
            this.doneClicked = true;
            this.item.numAnswered++;

            if (this.item.studentAnswer === this.item.correct) {
                this.item.grade = 100;
            } else {
                this.item.grade = 0;
            }

            this.playDoneSound();
        },
        chipColor(answer) {
            // if the student has not answered yet, we only highlight the selected answer
            if (!this.doneClicked) {
                if (answer === this.item.studentAnswer) {
                    return 'primary';
                } else {
                    return 'grey-darken-5';
                }
            }

            if (this.isTrainingMode) {
                if (answer === this.item.studentAnswer) {
                    if (answer === this.item.correct) {
                        return 'green';
                    } else {
                        return 'red';
                    }
                } else {
                    return 'grey-darken-5';
                }
            }

            // quiz mode, we provide feedback for all answers
            if (answer === this.item.correct) {
                return 'green';
            } else {
                return 'red';
            }
        },
    }
}
</script>

<style scoped>
.custom-chip-border {
    margin-top: -4px;
    margin-bottom: -4px;
    border: 1px solid grey;
}
</style>