<template>
  <ItemForm :index="index">
    <v-card-text>
      <v-text-field :label="$t('activity_editor.title')" variant="underlined" v-model="item.title"
        hide-details autofocus></v-text-field>
      <v-radio-group class="mt-3" density="comfortable" v-model="item.titleType" inline hide-details
        style="margin-bottom: -15px;">
        <v-radio color="primary" :label="$t('activity_editor.main_title')" value="h1"></v-radio>
        <v-radio color="primary" :label="$t('activity_editor.secondary_title')" value="h2"></v-radio>
      </v-radio-group>
    </v-card-text>
  </ItemForm>
</template>

<script>
import ItemForm from './ItemForm';

export default {
  name: 'TitleForm',
  components: {
    ItemForm
  },
  extends: ItemForm,
}
</script>