<template>
    <ItemView v-if="hasContentToShow" :item="item" :index="index" :hasDoneButton="false" :bgColor="'#E1BEE7'">
        <v-locale-provider :rtl="isRtlLang(item.explanation)">
            <v-card-text>
                <p class="text-body-1">
                    <span v-html="markdown(item.explanation)"></span>
                    <speak-text-icon :text="item.explanation" />
                </p>
            </v-card-text>
        </v-locale-provider>
    </ItemView>
</template>

<script>
import ItemView from './ItemView.vue';
import SpeakTextIcon from './SpeakTextIcon.vue';

export default {
    components: {
        ItemView,
        SpeakTextIcon
    },
    extends: ItemView,
    computed: {
        hasContentToShow() {
            return this.item.explanation !== "";
        },
    },
}
</script>