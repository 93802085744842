// README
// - logout is handled here
// - Here we also listen to onAuthStateChanged
// - In case of adding a new entry in the navbar, note to update the router
// since we have there logic that defines which page requires authentication
// - Also update App.vue

<template>
    <nav class="navbar">
        <router-link class="link-style" v-if="!loggedIn" to="/auth"><span class="text-h6">{{ $t('navbar.login')
                }}</span></router-link>
        <span v-if="!loggedIn" class="separator text-h6">|</span>
        <router-link class="link-style" v-if="loggedIn" to="/manage"><span class="text-h6">{{ $t('navbar.my_activities')
                }}</span></router-link>
        <span v-if="loggedIn" class="separator text-h6">|</span>
        <!-- <router-link class="link-style" v-if="loggedIn" to="/create"><span class="text-h6">{{
            $t('navbar.create_activity')
        }}</span></router-link> -->
        <!-- We currently do not present the "answers" page, not part of the MVP -->
        <!-- <span v-if="loggedIn" class="separator text-h6">|</span>
        <router-link class="link-style" v-if="loggedIn" to="/answers"><span class="text-h6">{{
            $t('navbar.student_answers')
        }}</span></router-link> -->
        <!-- <span v-if="loggedIn" class="separator text-h6">|</span> -->
        <router-link class="link-style" v-if="loggedIn" to="/settings"><span class="text-h6">{{ $t('navbar.settings')
                }}</span></router-link>
        <span v-if="loggedIn" class="separator text-h6">|</span>
        <router-link class="link-style" to="/help"><span class="text-h6">{{ $t('navbar.help')
                }}</span></router-link>
        <span v-if="loggedIn" class="separator text-h6">|</span>
        <button v-if="loggedIn" @click="logout"><span class="text-h6">{{ $t('navbar.logout') }}</span></button>
    </nav>
</template>

<script>
import { signOut } from 'firebase/auth';
import { auth } from '../firebase' // import auth service
import { getUserSettings, saveUserSettings } from '@/utils/firebaseUtils';
import { mapState } from 'vuex';

export default {
    computed: {
        loggedIn() {
            return this.$store.state.user.loggedIn;
        },
        userSettings() {
            return this.$store.state.userSettings;
        },
        ...mapState(['user'])
    },
    watch: {
        'user.data': {
            handler(user) {
                if (user) {
                    this.initializeUser(user);
                }
            },
        },
    },
    methods: {
        async logout() {
            try {
                await signOut(auth);
                // reset store state
                this.$store.dispatch('logout');
                this.$router.push('/auth');
            } catch (error) {
                console.error(error);
            }
        },
        async initializeUser(user) {
            this.$store.dispatch('loadActivities', user.uid);
            const settings = await getUserSettings(user.uid);
            if (!settings) { // new user
                await saveUserSettings({ // save default settings
                    ...this.userSettings,
                    name: user.displayName,
                    uid: user.uid
                });
            } else { // existing user
                this.$store.dispatch('setUserSettings', settings);
            }
        },
    },
    created() {
        // had an annoying bug. this was called multiple times (don't know why)
        // which caused the creation of multiple docs in the settings collection
        // solution: took the initialization code from here to a seperate function
        // which it triggered by a store change.
        auth.onAuthStateChanged(user => {
            this.$store.dispatch('setUser', user);
        });
    },

}
</script>

<style scoped>
.separator {
    margin: 0 0.5em;
}

.link-style {
    text-decoration: none;
    color: inherit;
    font-weight: normal;
}

.router-link-exact-active {
    color: purple;
}

.navbar {
    margin-top: 10px;
    margin-bottom: 25px;
}
</style>