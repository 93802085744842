<template>
    <div class="text-center pa-4">
        <v-card class="certificate-card">
            <v-card-title class="certificate-title">זאת לתעודה</v-card-title>
            <div class="certificate-body">
                <span>כי</span><br>
                <span class="certificate-name">{{ studentName }}</span><br>
                <span>השלימ/ה את הפעילות</span><br>
                {{ activityName }}<br>
                <!-- show the grade activity.grade -->
                <span>בציון {{ grade }}</span><br><br>
            </div>
            <span class="applogo">LetsStudy.io</span>
            <v-card-actions style="justify-content: center;">
                <v-btn size="x-large" color="green darken-1" text @click="shareOnWhatsApp">
                    שיתוף
                    <v-icon left>mdi-whatsapp</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { writeCertificate } from '@/utils/firebaseUtils';  // Import the writeCertificate function

export default {
    props: {
        studentName: String,
        activityName: String,
        grade: Number,
    },
    methods: {
        async shareOnWhatsApp() {
            let certificateId = await writeCertificate(this.studentName, this.grade, this.activityName);
            // Generate a URL that points to the certificate using the document ID
            let certificateUrl = `https://letsstudy.io/certificate?id=${certificateId}`;
            const message = encodeURIComponent(`זאת לתעודה כי:\n*${this.studentName}*\nהשלימ/ה את הפעילות\n${this.activityName}\nבציון ${this.grade}\n${certificateUrl}`);
            window.open(`https://wa.me/?text=${message}`, '_blank');
            // notify the parent component that the certificate was shared
            this.$emit('certificate-shared');
        },
    },
};
</script>

<style scoped>
.certificate-card {
    background: #F3E5F5;
    border: 2px solid #6A1B9A;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.certificate-title {
    text-align: center;
    font-family: "Rubik Doodle Shadow", system-ui;
    font-weight: 400;
    font-size: 50px;
    font-style: normal;
    color: purple;
}

.certificate-name {
    text-align: center;
    font-family: "Rubik Doodle Shadow", system-ui;
    font-weight: 600;
    font-size: 50px;
    font-style: normal;
    color: purple;
}

.certificate-body {
    text-align: center;
    font-family: "Rubik Doodle Shadow", system-ui;
    font-weight: 600;
    font-size: 30px;
    font-style: normal;
    color: black;
}

.applogo {
    font-family: Arial, sans-serif;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: purple;
}
</style>