// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDVhVFHmg5yE7_yr4QzRkvURuErJ33Q1s8",
    authDomain: "letsstudy-95c54.firebaseapp.com",
    projectId: "letsstudy-95c54",
    storageBucket: "letsstudy-95c54.appspot.com",
    messagingSenderId: "442255397851",
    appId: "1:442255397851:web:181c894c905671e9648dfe",
    measurementId: "G-2NNJ95DJ1D"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };