<!-- Implementation notes
- Besides the activity itself we also provide a switch to enable text2speech and a select box to select the voice.
- Important: only show the switch after the enablement and not when the component is shown.
- Why? since it takes time for the voices to be loaded.<template>
- Moreover, we call once the get voices method in mounted since it causes loading.
- Bottom line: we intentionally delay the loading of the voices until the user enables text2speech!
//  -->
<template>
    <v-row>
        <!-- Activity settings - hidden for xs/sm -->
        <v-col cols="3" class="side-panel hidden-sm-and-down">
            <SolveActivitySettings v-if="activityFetched" />
            <div class="created-with"><a href="https://letsstudy.io" target="_blank">{{
                $t('solve_activity.created_with') }}</a></div>

        </v-col>
        <!-- The activity -->
        <!-- pl-10 for xs/sm, pl-5 for > md -->
        <v-col cols="12" md="6" class="activity-preview pl-10 pr-10 pl-md-5 pr-md-5 pt-5"
            :style="{ backgroundImage: `url(${selectedImage})` }">
            <!-- Marker for scrolling -->
            <div ref="startOfActivity"></div>
            <ActivityPreview v-if="activityFetched" />
            <div v-if="errorMessage" style="margin-top: 30px;">
                <p class="text-h4">Activity not found or access denied</p>
                <p class="text-h4">הפעילות לא נמצאה או אין הרשאת גישה</p>
            </div>
        </v-col>
        <!-- User panel - hidden for xs/sm -->
        <v-col cols="3" class="side-panel hidden-sm-and-down" align="start">
            <div class="user-panel" v-if="activityFetched">
                <div>
                    <!-- Activity progress indication -->
                    <v-progress-linear v-model="completion" color="purple" height="25" rounded rounded-bar
                        bg-color="white" class="mb-2" :reverse="$isRTL() ? true : false">
                        <span v-if="$isRTL()" class="text-white">{{ totalQuestions }} / {{ answeredCount }}</span>
                        <span v-else class="text-white">{{ answeredCount }} / {{ totalQuestions }}</span>
                    </v-progress-linear>
                    <!-- Show current interaction mode -->
                    <p v-if="activity.interactionMode === 'training'" class="text-h6 text-green text-center">{{
                $t('solve_activity.training_mode') }}
                        <v-tooltip :text="$t('solve_activity.training_help')">
                            <template v-slot:activator="{ props }">
                                <v-icon size="x-small" color="green" v-bind="props">mdi-help-circle-outline</v-icon>
                            </template>
                        </v-tooltip>
                    </p>
                    <p v-if="activity.interactionMode === 'quiz'" class="text-h6 text-yellow text-center">{{
                $t('solve_activity.quiz_mode')
            }}
                        <v-tooltip :text="$t('solve_activity.quiz_help')">
                            <template v-slot:activator="{ props }">
                                <v-icon size="x-small" color="yellow" v-bind="props">mdi-help-circle-outline</v-icon>
                            </template>
                        </v-tooltip>
                    </p>
                    <!-- Switch between interaction modes -->
                    <v-row dense>
                        <v-col cols="12" align="center">
                            <!-- Switch between interaction modes -->
                            <!-- Is not available if originalActivity.interactionMode == 'quiz'  -->
                            <v-btn v-if="originalActivity && originalActivity.interactionMode === 'training'"
                                variant="outlined" class="mt-3" color="white" prepend-icon="mdi-swap-horizontal"
                                @click="toggleInteractionModeAndRestart()">
                                {{ activity.interactionMode === 'training' ? $t('solve_activity.switch_to_quiz') :
                $t('solve_activity.switch_to_training') }}
                            </v-btn>
                        </v-col>
                        <v-col cols="12" align="center">
                            <!-- Restart activity button -->
                            <v-btn prepend-icon="mdi-restart" variant="outlined" class="mt-3" color="white"
                                @click="restartActivity()">{{
                $t('solve_activity.restart_activity')
            }}</v-btn>
                        </v-col>
                    </v-row>
                    <!-- Show the grade upon completing the activity -->
                    <p v-if="fullyAnswered" class="text-green mt-3 text-h6 text-center">{{
                $t('solve_activity.your_grade_is', {
                    grade: averageGrade
                }) }}</p>
                    <!-- Upon quiz completion - get name and show certificate -->
                    <v-row dense class="mt-3" v-if="fullyAnswered && activity.interactionMode === 'quiz'">
                        <!-- Text field for student's name -->
                        <v-col cols="12">
                            <v-text-field v-model="username" bg-color="white" :label="$t('solve_activity.my_name')"
                                density="compact" variant="solo" hide-details></v-text-field>
                        </v-col>
                        <!-- Show certificate button -->
                        <v-col cols="12" align="center">
                            <v-btn @click="dialog = true" variant="outlined" color="white"
                                prepend-icon="mdi-certificate">
                                {{ $t('certificate.show_certificate') }}
                            </v-btn>
                            <v-locale-provider :rtl="true">
                                <v-dialog v-model="dialog" max-width="600px">
                                    <StudentCertificate :studentName="username" :activityName="activity.name"
                                        :grade="averageGrade" @certificate-shared="handleCertificateShared" />
                                </v-dialog>
                            </v-locale-provider>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-col>
        <SnackBar />
    </v-row>
</template>

<script>
import { getActivity } from '@/utils/firebaseUtils';
import ActivityPreview from '@/components/activity/ActivityPreview.vue'
import SolveActivitySettings from '@/components/activity/SolveActivitySettings.vue';
import SnackBar from '@/components/SnackBar.vue';
import StudentCertificate from '@/components/StudentCertificate.vue';

export default {
    name: 'SolveActivity',
    components: {
        ActivityPreview,
        SolveActivitySettings,
        SnackBar,
        StudentCertificate,
    },
    data() {
        return {
            images: [
                require('@/assets/dots_1280.jpg'),
                require('@/assets/leaves_1280.png'),
                require('@/assets/seamless_1280.jpg'),
            ],
            selectedImage: '',
            errorMessage: false,
            activityFetched: false,
            originalActivity: null,
            username: '',
            dialog: false,
        };
    },
    computed: {
        activity() {
            return this.$store.state.activity;
        },
        questionItems() {
            return this.activity.items.filter(item => Object.prototype.hasOwnProperty.call(item, 'grade'));
        },
        answeredItems() {
            return this.questionItems.filter(item => item.grade !== null);
        },
        fullyAnswered() {
            return this.questionItems.every(item => item.grade !== null);
        },
        averageGrade() {
            const gradedItems = this.questionItems.filter(item => item.grade !== null);
            const totalGrade = gradedItems.reduce((total, item) => total + item.grade, 0);
            return (gradedItems.length > 0) ? Math.round(totalGrade / gradedItems.length) : 0;
        },
        completion() {
            if (this.questionItems.length === 0) {
                return 0;
            }
            return (this.answeredItems.length / this.questionItems.length) * 100;
        },
        answeredCount() {
            if (this.answeredItems) {
                return this.answeredItems.length;
            } else {
                return 0;
            }
        },
        totalQuestions() {
            if (this.questionItems) {
                return this.questionItems.length;
            } else {
                return 0;
            }
        },
    },
    methods: {
        async fetchActivity() {
            const activityId = this.$route.query.id;
            try {
                const activity = await getActivity(activityId);
                // save a local copy (for restart activity button)
                this.originalActivity = JSON.parse(JSON.stringify(activity));
                // change app locale here, before the activity is set
                this.$setLocale(activity.locale);
                // copilot suggests to use dispath and not directly commit
                // since we are dealing with async code
                this.$store.dispatch('replaceActivity', activity);
                this.activityFetched = true;
                document.title = "Let's Study | " + activity.name;
            } catch (error) {
                // missing or insufficient permissions / activity not found
                console.error(error);
                this.errorMessage = true;
            }
        },
        restartActivity() {
            // save existing interaction mode, we want to keep it
            const interactionMode = this.activity.interactionMode;
            // we must save a clone of the original activity, or else it will be modified
            this.$store.commit('setActivity', JSON.parse(JSON.stringify(this.originalActivity)));
            this.activity.interactionMode = interactionMode;
            this.username = '';

            // scroll to the top of the activity
            this.$nextTick(() => {
                this.$refs.startOfActivity.scrollIntoView({ behavior: 'smooth' });
            });
        },
        toggleInteractionModeAndRestart() {
            this.activity.interactionMode = this.activity.interactionMode === 'training' ? 'quiz' : 'training';
            this.restartActivity();
        },
        handleCertificateShared() {
            this.restartActivity();
            this.username = '';
            this.dialog = false;
        },
    },
    async created() {
        this.selectedImage = this.images[Math.floor(Math.random() * this.images.length)];
        await this.fetchActivity();
    },
};
</script>
<style scoped>
.side-panel {
    background-color: black;
    box-sizing: border-box;
    min-height: 100vh;
}

.activity-preview {
    background-repeat: repeat;
    background-size: 25%;
    min-height: 100vh;
    box-sizing: border-box;
}

.user-panel {
    position: fixed;
    margin: 20px;
}

.created-with {
    position: fixed;
    bottom: 0;
    left: 0;
    padding-left: 15px;
    padding-bottom: 10px;
    font-size: 0.8em;
    color: white;
}

.created-with a {
    color: white;
    text-decoration: none;
}
</style>