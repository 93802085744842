// training mode:
// - the student can type an answer and press 'done' many times
// - after each press, the student gets feedback for his answer
// quiz mode:
// - the student can type an answer and press 'done' only once
// - after the first press, the student gets feedback for his answer (same as training mode)
<template>
    <ItemView v-if="hasContentToShow" :item="item" :index="index" @done="gradeQuestion">
        <v-card-text style="min-width: 350px;">
            <p class="text-body-1 mb-2">
                <span v-html="markdown(item.question)"></span>
                <span class="text-grey-darken-1"> ({{ $tc('activity_preview.words', wordsInCorrectAnswer)
                    }})</span>
                <speak-text-icon :text="item.question" />
            </p>
            <v-col cols="12">
                <v-locale-provider :rtl="isRtlLang(item.studentAnswer)">
                    <v-text-field v-model="item.studentAnswer" variant="outlined" density="comfortable"
                        :disabled="doneClicked && !isTrainingMode" hide-details />
                </v-locale-provider>
            </v-col>
        </v-card-text>
    </ItemView>
</template>

<script>
import ItemView from './ItemView.vue';
import SpeakTextIcon from './SpeakTextIcon.vue';

export default {
    components: {
        ItemView,
        SpeakTextIcon
    },
    extends: ItemView,
    data() {
        return {
            doneClicked: false,
        }
    },
    computed: {
        hasContentToShow() {
            return this.item.question !== "";
        },
        wordsInCorrectAnswer() {
            if (this.item.possibleAnswers[0] === "") {
                return 0;
            }
            return this.item.possibleAnswers[0].split(' ').length;
        }
    },
    watch: {
        // when the teacher changes the form, we "reset" the view
        'item.possibleAnswers': {
            handler() {
                this.handleChangeInQuestionForm();
            },
            deep: true
        },
        'item.question': {
            handler() {
                this.handleChangeInQuestionForm();
            },
            deep: true
        },
        'item.studentAnswer': {
            handler() {
                this.item.grade = null; // hide feedback after student changes his answer
            }
        }
    },
    methods: {
        handleChangeInQuestionForm() {
            this.item.grade = null; // reset grade
            this.item.studentAnswer = ""; // reset student answer
            this.doneClicked = false;
            this.item.numAnswered = 0;
        },
        gradeQuestion() {
            // we allow to press 'check' with an empty answer
            // (previously we returned if student didn't answer)
            // rational: student doesn't know the answer, and wants to see the explanation

            if (this.doneClicked && !this.isTrainingMode) {
                return;
            }
            this.doneClicked = true;
            this.item.numAnswered++;

            // remove empty answers (otherwise student can get full credit by submitting an empty answer...)
            let possibleAnswersNonEmpty = this.item.possibleAnswers.filter(answer => answer !== "");

            // apply cleanText to each possible answer
            possibleAnswersNonEmpty = possibleAnswersNonEmpty.map(this.cleanText);

            // if studentAnswer is one of the possible answers, we give full credit
            // ignore case
            if (possibleAnswersNonEmpty.some(answer => answer.toLowerCase() === this.cleanText(this.item.studentAnswer.toLowerCase()))) {
                this.item.grade = 100;
            } else {
                this.item.grade = 0;
            }

            this.playDoneSound();
        },
        cleanText(text) {
            return text.replace(/[!?.,;:-]/g, '');
        }
    }
}
</script>