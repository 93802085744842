// README
// - We load different collections based on the environment (development, production)
// - The VUE_APP_ENV var is set in .env.development and .env.production
// - Had a big issue with the store, see notes below.

import { db } from '../firebase';
import { collection, collectionGroup, addDoc, doc, setDoc, getDoc, deleteDoc, query, where, getDocs, onSnapshot, serverTimestamp, orderBy, limit } from 'firebase/firestore';
import { clearStudentSelections } from '@/components/activity/models/activity';
import store from '@/store';

const env = process.env.VUE_APP_ENV;
// define each new collection that way:
export const activitiesCollection = env === 'development' ? 'activities-dev' : 'activities';
export const foldersCollection = env === 'development' ? 'folders-dev' : 'folders';
// subcollection of activities, currently not in use"
export const answersCollection = env === 'development' ? 'answers-dev' : 'answers';
export const settingsCollection = env === 'development' ? 'settings-dev' : 'settings';
export const certificatesCollection = env === 'development' ? 'certificates-dev' : 'certificates';

// important!
// vuex might not preserve the class type of the items in the activity
// i.e., an item may be a subclass of BaseItem OR a plain object.
// Therefore, we need to calls toObject() only for non-plain objects
// (firestore expects plain objects)

export const saveActivity = async (activity) => {
    // Convert items to plain objects, otherwise firebase will complain
    // if an item does not have toObject method then add as it is
    activity.items = activity.items.map(item => item.toObject ? item.toObject() : item);

    // for each item, call clearStudentSelections(item)
    // (the selections were made by teacher when creating and experimenting with the activity)
    activity.items.forEach(item => clearStudentSelections(item));

    // we don't use serverTimestamp() because we save all activities locally
    // and when we use serverTimestamp we get a placeholder value.
    activity.updatedAt = new Date().toISOString();

    // Update an existing activity
    if (activity.docRef) {
        const docRef = doc(db, activitiesCollection, activity.docRef);
        await setDoc(docRef, activity);
        store.commit('updateActivity', activity);
        return docRef.id;
    } else { // Create a new activity
        const docRef = await addDoc(collection(db, activitiesCollection), activity);
        activity.docRef = docRef.id;
        await setDoc(docRef, activity);
        store.commit('addActivity', activity);
        return docRef.id;
    }
};

export async function getActivity(activityId) {
    const docRef = doc(db, activitiesCollection, activityId);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
}

export async function getActivities(userId) {
    const q = query(collection(db, activitiesCollection), where('uid', '==', userId));
    const querySnapshot = await getDocs(q);
    const activities = querySnapshot.docs.map(doc => doc.data());
    return activities;
}

export async function getActivitiesByFolder(folderId) {
    const q = query(collection(db, activitiesCollection), where('folder', '==', folderId));
    const querySnapshot = await getDocs(q);
    const activities = querySnapshot.docs.map(doc => doc.data());
    return activities;
}

export async function addFolder(uid, name) {
    const docRef = await addDoc(collection(db, foldersCollection), { name, uid: uid });
    return docRef.id;
}
export async function getUserSettings(uid) {
    // only return the doc with the uid
    const q = query(collection(db, settingsCollection), where('uid', '==', uid));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
        const settings = querySnapshot.docs[0].data();
        return settings;
    } else {
        return null;
    }
}

export async function saveUserSettings(settings) {
    if (settings.docRef) {
        const docRef = doc(db, settingsCollection, settings.docRef);
        await setDoc(docRef, settings);
        store.commit('SET_USER_SETTINGS', settings);
        return docRef.id;
    } else { // Create a new settings object
        const docRef = await addDoc(collection(db, settingsCollection), settings);
        settings.docRef = docRef.id;
        await setDoc(docRef, settings);
        store.commit('SET_USER_SETTINGS', settings);
        return docRef.id;
    }
}

export function getFolders(uid, callback) {
    const q = query(collection(db, foldersCollection), where('uid', '==', uid));
    return onSnapshot(q, callback);
}

export async function getFolder(folderId) {
    const docRef = doc(db, foldersCollection, folderId);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
}

export function deleteFolder(folderId) {
    const folderRef = doc(db, foldersCollection, folderId);
    deleteDoc(folderRef);
}

export const deleteActivity = async (activityId) => {
    const activityRef = doc(db, activitiesCollection, activityId);
    await deleteDoc(activityRef);
    store.commit('deleteActivity', activityId);
};

export const writeAnswer = async (username, grade, activity) => {
    const activityRef = doc(db, activitiesCollection, activity.docRef);
    const answersCollectionRef = collection(activityRef, answersCollection);

    const answerData = {
        username: username,
        grade: grade,
        activityName: activity.name,
        createdAt: serverTimestamp(),
        interactionMode: activity.interactionMode,
        teacher: activity.uid,
    };

    return await addDoc(answersCollectionRef, answerData);
}

export const getAnswers = async (teacherId, num = 20) => {
    const answersQuery = query(
        collectionGroup(db, answersCollection),
        where("teacher", "==", teacherId),
        orderBy("createdAt", "desc"),
        limit(num)
    );

    const querySnapshot = await getDocs(answersQuery);
    const answers = querySnapshot.docs.map(doc => doc.data());
    return answers;
}

export const writeCertificate = async (studentName, grade, activityName) => {
    // Create a reference to the certificates collection
    const certificatesCollectionRef = collection(db, certificatesCollection);

    // Define the data for the new certificate
    const certificateData = {
        studentName: studentName,
        grade: grade,
        activityName: activityName,
        createdAt: serverTimestamp(),
    };

    // Add the new certificate to the certificates collection
    const certificateRef = await addDoc(certificatesCollectionRef, certificateData);

    // Return the ID of the new certificate
    return certificateRef.id;
};

export const readCertificate = async (certificateId) => {
    const certificateDocRef = doc(db, certificatesCollection, certificateId);
    const certificateDoc = await getDoc(certificateDocRef);
    return certificateDoc.data();
};
