<template>
  <ItemForm :index="index">
    <v-card-text>
      <v-textarea class="mb-3" variant="underlined" :label="$t('activity_editor.question')" v-model="item.question"
        rows="1" hide-details auto-grow autofocus @focus="setActiveItem()"></v-textarea>
      <v-row dense>
        <v-col cols="6">
          <v-text-field density="compact" v-model="item.correct" :label="$t('activity_editor.correct')" hide-details
            variant="outlined" @focus="setActiveItem()"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6" v-for="(distractor, i) in item.distractors" :key="i">
          <v-text-field density="compact" v-model="item.distractors[i]" :label="`${$t('activity_editor.distractor')} ${i + 1}`" hide-details
            variant="outlined" @focus="setActiveItem()"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </ItemForm>
</template>

<script>
import ItemForm from './ItemForm';

export default {
  name: 'MultipleChoiceQuestionForm',
  components: {
    ItemForm
  },
  extends: ItemForm,
}
</script>