<template>
    <v-card variant="flat" v-if="selectedQuestion">
        <v-form ref="form">
            <v-card-text>
                <v-btn-toggle v-model="currGeneratorName" color="grey-darken-5" mandatory style="height: 25px;" divided
                    variant="outlined">
                    <v-btn size="small" :value="generator.name" v-for="(generator, index) in activeGenerators"
                        :key="index" @click="selectGenerator(generator)">
                        {{ generator.name }}
                    </v-btn>
                </v-btn-toggle>
                <v-textarea v-model="input" :label="currGenerator.label()" :placeholder="currGenerator.placeholder()"
                    rows="10" :rules="[currGenerator.validateInput]"></v-textarea>
            </v-card-text>

            <v-card-actions style="margin-top: -15px; margin-left: 10px;">
                <v-btn variant="tonal" text @click="generateQuestions">{{ $t('wizard.generate') }}</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import { MultipleChoiceGenerator, TrueFalseGenerator, SortItemsGenerator, ShortAnswerGenerator, MarkWordsGenerator, MultipleChoiceAutoDistractorsGenerator } from './QuestionGenerators';

export default {
    props: {
        selectedQuestion: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            input: '',
            currGeneratorName: null,
            currGenerator: null,
            generators: [
                { name: this.$t('activity_editor.multiple_choice'), class: MultipleChoiceGenerator, type: 'multiple_choice' },
                { name: this.$t('activity_editor.multiple_choice_nodist'), class: MultipleChoiceAutoDistractorsGenerator, type: 'multiple_choice' },
                { name: this.$t('activity_editor.true_false'), class: TrueFalseGenerator, type: 'true_false' },
                { name: this.$t('activity_editor.sort_items'), class: SortItemsGenerator, type: 'sort_items' },
                { name: this.$t('activity_editor.short_answer'), class: ShortAnswerGenerator, type: 'short_answer' },
                { name: this.$t('activity_editor.mark_words'), class: MarkWordsGenerator, type: 'mark_words' },
                // Add more generators here
            ],
        };
    },
    computed: {
        activeGenerators() {
            // return from this.generators only the generators that have the same type as this.selectedQuestion
            // basically there only should be one generator that has the same type as this.selectedQuestion...
            // however for example multiple_choice has two generators
            return this.generators.filter(generator => generator.type === this.selectedQuestion);
        },
    },
    watch: {
        selectedQuestion() {
            var generator = this.activeGenerators[0];
            this.selectGenerator(generator);
            this.input = '';
        }
    },
    methods: {
        ...mapMutations(['UPDATE_ITEMS']),
        selectGenerator(generator) {
            this.currGenerator = new generator.class();
            this.currGeneratorName = generator.name;
        },
        async generateQuestions() {
            const { valid } = await this.$refs.form.validate();

            if (!valid) {
                return;
            }

            var questions = this.currGenerator.generateQuestions(this.input);

            // Append the questions to store.activity.items
            this.UPDATE_ITEMS([...this.$store.state.activity.items, ...questions]);
            // Emit an event to the parent component
            this.$emit('questionsGenerated');
        },
    },
    created() {
        var generator = this.activeGenerators[0];
        this.selectGenerator(generator);
    },

};
</script>