<template>
    <div class="mt-5">
        <v-row justify="center">
            <v-col cols="12" md="6">
                <v-text-field variant="outlined" :label="$t('settings.name')"
                    v-model="localUserSettings.name"></v-text-field>
                <v-select variant="outlined" :items="languages" :label="$t('settings.language')" item-title="text"
                    item-value="value" v-model="localUserSettings.language"></v-select>
                <v-btn color="primary" @click="updateSettings">{{ $t('settings.save_settings') }}</v-btn>
                <div class="mt-10">
                    <div v-if="localUserSettings.isPremium">
                        <span v-html="$t('settings.premium_account')"></span><br>
                        <span v-html="$t('settings.downgrade_to_regular')"></span><br>
                        <span><i>oren.mishali@gmail.com</i></span>
                    </div>
                    <div v-else>
                        <span v-html="$t('settings.regular_account')"></span><br>
                        <span v-html="$t('settings.upgrade_to_premium')"></span><br>
                        <span><i>oren.mishali@gmail.com</i></span>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
    <SnackBar />
</template>

<script>
import { mapState } from 'vuex';
import { saveUserSettings } from '@/utils/firebaseUtils';
import SnackBar from '@/components/SnackBar.vue';

export default {
    components: {
        SnackBar,
    },
    data() {
        return {
            localUserSettings: {},
            languages: [
                { text: 'English', value: 'en' },
                { text: 'עברית', value: 'he' }
            ],
        };
    },
    computed: {
        ...mapState(['userSettings']),
    },
    methods: {
        updateSettings() {
            // saveUserSettings also updates the store,
            // which causes App.vue to update the app locale if needed
            saveUserSettings({ ...this.localUserSettings })
                .then(() => {
                    this.$showSnackbar(this.$t('settings.saved_message'));
                });
        },
    },
    created() {
        this.localUserSettings = JSON.parse(JSON.stringify(this.userSettings));
    },
};
</script>