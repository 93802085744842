<template>
  <ItemForm :index="index">
    <v-card-text>
      <v-textarea @focus="setActiveItem()" class="mb-3" variant="underlined" :label="$t('activity_editor.question')"
        v-model="item.question" rows="1" hide-details auto-grow autofocus></v-textarea>
      <v-row dense>
        <v-col cols="6" v-for="(possibleAnswer, i) in item.possibleAnswers" :key="i">
          <v-text-field @focus="setActiveItem()" density="compact" v-model="item.possibleAnswers[i]"
            :label="$t('activity_editor.answer') + ' ' + (i + 1)" hide-details variant="outlined"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-checkbox v-model="item.setDefaultFeedback" density="compact" color="primary"
            :label="$t('activity_editor.short_answer_default_explanation')" hide-details
            style="margin-bottom: -10px;"></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </ItemForm>
</template>

<script>
import ItemForm from './ItemForm';

export default {
  name: 'ShortAnswerQuestionForm',
  components: {
    ItemForm
  },
  extends: ItemForm,
  // data() {
  //   return {
  //     setExplanation: false
  //   };
  // },
  watch: {
    // without this.item check an error is thrown when the item is deleted
    'item.setDefaultFeedback': {
      handler() {
        if (this.item && this.item.setDefaultFeedback) {
          this.setDefaultExplanation();
        }
      },
      immediate: true
    },
    'item.possibleAnswers': {
      handler() {
        if (this.item && this.item.setDefaultFeedback) {
          this.setDefaultExplanation();
        }
      },
      deep: true
    }
  },
  methods: {
    setDefaultExplanation() {
      this.item.feedback = this.item.possibleAnswers.filter(answer => answer.trim() !== '').join(', ');
    }
  },
  created() {
    // backword compatibility:
    // if there is no item.setDefaultFeedback, create one and set it to false
    if (this.item && !this.item.setDefaultFeedback) {
      this.item.setDefaultFeedback = false;
    }
  }
}
</script>