<template>
    <div class="certificate-page">
        <StudentCertificate :studentName="certificate.studentName" :activityName="certificate.activityName"
            :grade="certificate.grade" />
    </div>
</template>

<script>
import { readCertificate } from '@/utils/firebaseUtils';
import StudentCertificate from '@/components/StudentCertificate.vue';

export default {
    components: {
        StudentCertificate
    },
    data() {
        return {
            certificate: null
        };
    },
    async created() {
        let certificateId = this.$route.query.id;
        this.certificate = await readCertificate(certificateId);
    }
}
</script>

<style scoped>
.certificate-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
}
</style>