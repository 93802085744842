import { createStore } from 'vuex'
import { getActivities } from '@/utils/firebaseUtils';


// notes:
// - state is reseted after user logs out
// defined separately so that we can e.g. reset the state
// defined as a function to ensure that each time it's invoked,
// a new object with the initial state is created
const initialActivityState = () => ({
  docRef: null,
  name: '',
  showGrade: true, // whether to show the grade to the user (or to show only icon)
  interactionMode: 'training', // student can answer many times, should also support 'quiz' (one time)
  voiceSupport: false, // whether to show text-to-speech
  items: [],
  locale: 'he', // when a new activity is created, the locale is set to the UI's locale. This value affects folder view directionality
  updatedAt: null,
  folder: null,
});
const initialState = () => ({
  activity: initialActivityState(),
  user: {
    loggedIn: false,
    data: null
  },
  userSettings: { // see Navbar.vue for its usage
    docRef: null,
    language: 'he',
    name: null,
    uid: null,
    isPremium: false,
  },
  snackbar: {
    show: false,
    message: ''
  },
  text2speech: {
    enabled: false,
    selectedVoice: null,
  },
  activitySnapshot: null,
  muted: false,
  // activities mirror the firestore collection
  // why? save real-time updates
  activities: [],
  activeItemIndex: null, // index of the active item form in the activity
});

export default createStore({
  state: initialState(),
  getters: {
    user: state => state.user,
    userSettings: state => state.userSettings,
    text2speech: state => state.text2speech,
    activity: state => state.activity,
    isDirty: (state) => {
      return JSON.stringify(state.activity) !== state.activitySnapshot;
    },
    muted: state => state.muted,
    activeItemIndex: state => state.activeItemIndex,
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_USER_SETTINGS(state, settings) {
      state.userSettings = settings;
    },
    SET_ACTIVE_ITEM_INDEX(state, index) {
      state.activeItemIndex = index;
    },
    // call when activity is saved
    SET_ACTIVITY_SNAPSHOT(state) {
      state.activitySnapshot = JSON.stringify(state.activity);
    },
    RESET_STATE(state) {
      Object.assign(state, initialState());
    },
    RESET_ACTIVITY_STATE(state) {
      state.activity = initialActivityState();
    },
    UPDATE_ITEMS(state, items) {
      state.activity.items = items;
    },
    SET_INTERACTION_MODE(state, interactionMode) {
      state.activity.interactionMode = interactionMode;
    },
    SET_VOICE_SUPPORT(state, voiceSupport) {
      state.activity.voiceSupport = voiceSupport;
    },
    SET_SHOW_GRADE(state, showGrade) {
      state.activity.showGrade = showGrade;
    },
    SET_ACTIVITY_NAME(state, name) {
      state.activity.name = name;
    },
    SET_ACTIVITY_LOCALE(state, locale) {
      state.activity.locale = locale;
    },
    SET_ACTIVITY_FOLDER(state, folderId) {
      state.activity.folder = folderId;
    },
    addItem(state, item) {
      state.activity.items.push(item);
    },
    deleteItem(state, index) {
      state.activity.items.splice(index, 1);
    },
    setInteractionMode(state, mode) {
      state.activity.interactionMode = mode;
    },
    setShowGrade(state, show) {
      state.activity.showGrade = show;
    },
    updateActivityDocRef(state, docRef) {
      state.activity.docRef = docRef;
    },
    showSnackbar(state, payload) {
      state.snackbar.message = payload.message;
      state.snackbar.show = true;
    },
    hideSnackbar(state) {
      state.snackbar.show = false;
    },
    setActivity(state, activity) {
      state.activity = activity;
      state.activitySnapshot = JSON.stringify(activity);
    },
    setMuted(state, muted) {
      state.muted = muted;
    },
    setActivities(state, activities) {
      state.activities = activities;
    },
    clearActivities(state) {
      state.activities = [];
    },
    addActivity(state, activity) {
      state.activities.push(activity);
    },
    updateActivity(state, updatedActivity) {
      const index = state.activities.findIndex(activity => activity.docRef === updatedActivity.docRef);
      if (index !== -1) {
        state.activities.splice(index, 1, updatedActivity);
      }
    },
    deleteActivity(state, activityId) {
      const index = state.activities.findIndex(activity => activity.docRef === activityId);
      if (index !== -1) {
        state.activities.splice(index, 1);
      }
      state.activity = initialActivityState();
    },
    toggleText2Speech: state => state.text2speech.enabled = !state.text2speech.enabled,
  },
  actions: {
    async loadActivities({ commit }, userId) {
      const activities = await getActivities(userId);
      commit('setActivities', activities);
    },
    setUser({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null);
      commit('SET_USER', user);
    },
    // call when activity is fetched from firestore
    // and needs to be shown
    replaceActivity({ commit }, activity) {
      commit('setActivity', activity);
    },
    logout({ commit }) {
      commit('RESET_STATE');
    },
    updateItems({ commit }, items) {
      commit('UPDATE_ITEMS', items);
    },
    addActivity({ commit }, activity) {
      commit('addActivity', activity);
    },
    updateActivity({ commit }, updatedActivity) {
      commit('updateActivity', updatedActivity);
    },
    setUserSettings({ commit }, settings) {
      commit('SET_USER_SETTINGS', settings);
    }
  }
})