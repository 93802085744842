<template>
    <v-snackbar v-model="show" :timeout="1500" @update:modelValue="hideSnackbar">
        {{ message }}
    </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            show: state => state.snackbar.show,
            message: state => state.snackbar.message
        })
    },
    methods: {
        hideSnackbar() {
            this.$store.commit('hideSnackbar');
        }
    }
};
</script>