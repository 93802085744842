// markdownMixin.js
import MarkdownIt from 'markdown-it';

export default {
    data() {
        const md = new MarkdownIt();

        // all this mess below causes all links to open in a new tab
        // why? by that the folder view is kept open and not being loaded
        // each time the user returns. And I think it is a better UX.
        const defaultRender = md.renderer.rules.link_open || function(tokens, idx, options, env, self) {
            return self.renderToken(tokens, idx, options);
        };

        md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
            // Add target="_blank" to all links
            const aIndex = tokens[idx].attrIndex('target');

            if (aIndex < 0) {
                tokens[idx].attrPush(['target', '_blank']); // add new attribute
            } else {
                tokens[idx].attrs[aIndex][1] = '_blank';    // replace value of existing attr
            }

            // pass token to default renderer.
            return defaultRender(tokens, idx, options, env, self);
        };

        return {
            md
        };
    },
    methods: {
        markdown(text) {
            return this.md.render(text);
        }
    }
}