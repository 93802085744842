<template>
  <ItemForm :index="index">
    <v-card-text>
      <v-textarea @focus="setActiveItem()" :label="$t('activity_editor.youtube_url')" variant="underlined" v-model="item.url" rows="1" hide-details auto-grow autofocus></v-textarea>
      <v-textarea @focus="setActiveItem()" :label="$t('activity_editor.caption')" variant="underlined" v-model="item.caption" rows="1" hide-details auto-grow></v-textarea>
    </v-card-text>
  </ItemForm>
</template>

<script>
import ItemForm from './ItemForm';

export default {
  name: 'YouTubeForm',
  components: {
    ItemForm
  },
  extends: ItemForm,
}
</script>