// training mode:
// - the student can sort and press 'done' many times
// - after each press, the student gets feedback for all answers
// (unike multiple choice, here we don't reveal the whole correct answer by that)
// quiz mode:
// - the student can select an answer and press 'done' only once
// - after the first press, the student gets feedback for all answers
<template>
    <ItemView v-if="hasContentToShow" :item="item" :index="index" @done="gradeQuestion">
        <v-card-text>
            <p class="text-body-1 mb-2">
                <span v-html="markdown(item.guideline)"></span>
                <speak-text-icon v-if="item.guideline" :text="item.guideline" />
            </p>
            <draggable v-model="item.studentSortedItems" :sort="canAnswer" group="items" @start="handleDragStart"
                @end="drag = false" :item-key="(element, index) => index">
                <template #item="{ element, index }">
                    <v-col cols="12" v-if="element !== ''">
                        <v-chip :color="chipColor(element, index)" class="custom-chip" style="width: 300px;">{{
                            element
                        }}</v-chip>
                        <speak-text-icon :text="element" />
                    </v-col> </template>
            </draggable>
        </v-card-text>
    </ItemView>
</template>

<script>
import ItemView from './ItemView.vue';
import draggable from 'vuedraggable';
import SpeakTextIcon from './SpeakTextIcon.vue';


export default {
    components: {
        ItemView,
        draggable,
        SpeakTextIcon
    },
    extends: ItemView,
    data() {
        return {
            drag: false,
            doneClicked: false,
        }
    },
    created() {
        this.item.studentSortedItems = this.shuffleItems([...this.correctAnswer]);
    },
    watch: {
        'item.sortedItems': {
            handler() {
                // we shuffle sortedItems after removing empty strings
                this.item.studentSortedItems = this.shuffleItems([...this.correctAnswer]);
                this.item.grade = null; // reset grade
                this.doneClicked = false;
                this.item.numAnswered = 0;
            },
            deep: true
        }
    },
    computed: {
        hasContentToShow() {
            // true if guideline is not empty or there is at least one non-empty sorted item
            return this.item.guideline !== "" || this.item.sortedItems.filter(item => item !== '').length > 0;
        },
        correctAnswer() {
            return this.item.sortedItems.filter(item => item !== '');
        },
    },
    methods: {
        shuffleItems(array) {
            // Fisher-Yates shuffle algorithm
            let currentIndex = array.length, temporaryValue, randomIndex;
            while (0 !== currentIndex) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }
            return array;
        },
        gradeQuestion() {
            this.doneClicked = true;
            this.item.numAnswered++;

            let score = 0;
            for (let i = 0; i < this.correctAnswer.length; i++) {
                if (this.correctAnswer[i] === this.item.studentSortedItems[i]) {
                    score++;
                }
            }
            this.item.grade = (score / this.correctAnswer.length) * 100;
            this.playDoneSound();
        },
        chipColor(element, index) {
            if (!this.doneClicked) {
                return 'grey-darken-5';
            }
            return this.correctAnswer[index] === element ? 'green' : 'red';
        },
        handleDragStart() {
            if (this.canAnswer) {
                this.drag = true;
                this.doneClicked = false;
                this.item.grade = null; // reset grade
            }
        },
    }
}
</script>

<style scoped>
.custom-chip {
    cursor: move;
}
</style>