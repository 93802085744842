<template>
  <ItemForm :index="index">
    <v-card-text>
      <v-text-field @focus="setActiveItem()" :label="$t('activity_editor.title')" variant="underlined"
        v-model="item.title" hide-details></v-text-field>
      <v-textarea @focus="setActiveItem()" :label="$t('activity_editor.text')" variant="underlined" v-model="item.text"
        rows="1" max-rows="8" hide-details auto-grow autofocus></v-textarea>
    </v-card-text>
  </ItemForm>
</template>

<script>
import ItemForm from './ItemForm';

export default {
  name: 'TextForm',
  components: {
    ItemForm
  },
  extends: ItemForm,
}
</script>