<template>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
        <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon variant="tonal" size="small"
                :class="{ 'ml-10': this.$isRTL(), 'mr-10': !this.$isRTL() }">
                <v-icon>mdi-magic-staff</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-locale-provider :rtl="false">
                <v-form>
                    <v-toolbar>
                        <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
                        <v-toolbar-title>{{ $t('wizard.title') }}</v-toolbar-title>
                    </v-toolbar>

                    <v-row dense>
                        <!-- Propmt Generator -->
                        <v-col cols="5">
                            <v-card-text>
                                <!-- Select the type of question to generate (first step) -->
                                <v-select class="mb-3" density="compact" v-model="selectedQuestion"
                                    :items="allQuestions" item-title="name" item-value="value"
                                    label="Select questions to generate" hide-details></v-select>
                                <!-- Select the prompt (second step) -->
                                <v-select v-if="selectedQuestion" density="compact" v-model="selectedPrompt"
                                    :items="promptsForQuestion" item-title="name" label="Select a prompt" return-object
                                    hide-details></v-select>
                                <div v-if="selectedPrompt" class="mt-5">
                                    <v-row dense>
                                        <v-col :cols="isTextarea(param) ? 12 : 6"
                                            v-for="(param, index) in selectedPrompt.params" :key="index">
                                            <v-textarea v-if="isTextarea(param)" v-model="paramValues[param.name]"
                                                :label="param.label" :bg-color="paramColors[param.name]"
                                                :rows="param.rows ? param.rows : 5" density="compact" hide-details
                                                no-resize></v-textarea>
                                            <v-text-field v-else v-model="paramValues[param.name]" :label="param.label"
                                                :bg-color="paramColors[param.name]" density="compact"
                                                hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                                <!-- Main prompt -->
                                <div v-if="selectedPrompt">
                                    <p class="mt-3 text-subtitle-1 font-weight-bold">Create the Questions</p>

                                    <p class="text-subtitle-1"><span v-html="generatedPrompt"></span></p>
                                    <!-- Add Copy to Clipboard button -->
                                    <v-btn append-icon="mdi-content-copy" @click="copyMainPromptToClipboard()"
                                        variant="tonal" size="small" class="mt-3">Copy
                                        to
                                        Clipboard</v-btn>
                                </div>
                                <!-- CSV prompt (optional) -->
                                <div v-if="selectedPrompt && selectedPrompt.csvTemplate">
                                    <p class="mt-3 text-subtitle-1 font-weight-bold">Pack as CSV File
                                    </p>
                                    <p class="text-subtitle-1"><span>{{ csvTemplate }}</span></p>
                                    <!-- Add Copy to Clipboard button -->
                                    <v-btn v-if="selectedPrompt" append-icon="mdi-content-copy"
                                        @click="copyCsvPromptToClipboard()" variant="tonal" size="small"
                                        class="mt-3">Copy
                                        to
                                        Clipboard</v-btn>
                                </div>
                                <div v-if="selectedPrompt">
                                    <p class="mt-3 text-subtitle-1 font-weight-bold">Paste the CSV File
                                        into the
                                        Question Editor.</p>
                                </div>
                                <div v-if="selectedPrompt && selectedPrompt.note" class="note">
                                    <p class="text-subtitle-1">{{ selectedPrompt.note }}</p>
                                </div>
                            </v-card-text>
                        </v-col>
                        <!-- Question Generator -->
                        <v-locale-provider :rtl="$isRTL()">
                            <v-col cols="7" v-if="selectedQuestion">
                                <QuestionGenerator @questionsGenerated="dialog = false"
                                    :selectedQuestion="selectedQuestion" />
                            </v-col>
                        </v-locale-provider>
                    </v-row>

                </v-form>
            </v-locale-provider>
        </v-card>
        <SnackBar />
    </v-dialog>
</template>

<script>
import prompts from './prompts.json';
import SnackBar from '@/components/SnackBar.vue';
import QuestionGenerator from './QuestionGenerator.vue';

export default {
    components: {
        SnackBar,
        QuestionGenerator,
    },
    data() {
        return {
            dialog: false,
            allPrompts: [],
            allQuestions: [{ name: 'Multiple-Choice Questions', value: 'multiple_choice' }, { name: 'True/False Questions', value: 'true_false' }, { name: 'Short-Answer Questions', value: 'short_answer' }],
            selectedPrompt: null,
            selectedQuestion: "",
            paramValues: {},
            paramColors: {},
            colors: ['red', 'green', 'blue', 'purple', 'orange', 'pink', 'brown', 'grey', 'teal', 'cyan'],
        };
    },
    computed: {
        generatedPrompt() {
            if (!this.selectedPrompt) return '';
            return this.selectedPrompt.template.replace(/{(\w+)}/g, (match, p1) => {
                const value = this.paramValues[p1] || '';
                //return `<span class="text-red">${value}</span>`;
                return `<span style="color: ${this.paramColors[p1]}; font-weight: bold;">${value}</span>`;

            });
        },
        csvTemplate() {
            if (!this.selectedPrompt) return '';
            return this.selectedPrompt.csvTemplate;
        },
        generatedPromptCopy() {
            return this.generatedPrompt.replace(/<[^>]*>?/gm, '');
        },
        promptsForQuestion() {
            if (!this.selectedQuestion) return [];
            return this.allPrompts.filter(prompt => prompt.forQuestions.includes(this.selectedQuestion));
        },
    },
    watch: {
        selectedPrompt(newPrompt) {
            if (newPrompt) {
                this.paramValues = {};
                newPrompt.params.forEach(param => {
                    this.paramValues[param.name] = param.default;
                });
                // iterate over the params (with index) and assign a color to each
                newPrompt.params.forEach((param, index) => {
                    this.paramColors[param.name] = this.colors[index % this.colors.length];
                });

            }
        },
    },
    methods: {
        copyMainPromptToClipboard() {
            navigator.clipboard.writeText(this.generatedPromptCopy);
            this.$showSnackbar("Prompt copied to clipboard");
        },
        copyCsvPromptToClipboard() {
            navigator.clipboard.writeText(this.csvTemplate);
            this.$showSnackbar("Prompt copied to clipboard");
        },
        isTextarea(param) {
            return param.isTextarea;
        },
    },
    created() {
        this.allPrompts = prompts;
    },
};
</script>

<style scoped>
.note {
    background-color: #F3E5AB;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
}
</style>